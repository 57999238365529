import "../Styles/CartProcess.css";

// import mc from "../Images/CartMotorcycle.svg";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { generatePath } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// import prepare from "../Images/prepare.svg";

function CartProcess4() {
  const { t, i18n } = useTranslation();
  const [estimate, setEstimate] = useState({});

  let history = useNavigate();

  //on clicking track order user id redirected to /order-track (OrderTracking component found in /Pages) with order id as params
  function handleProceed() {
    const id = localStorage.getItem("orderId");
    localStorage.setItem("process", 0);

    history(
      generatePath("/order-track/:id", {
        id,
      })
    );
  }

  //to display time estimate if order type is home delivery
  useEffect(() => {
    if (localStorage.getItem("typeId") == 2) {
      const estimate = localStorage.getItem("timeEstimate");

      if (estimate) {
        setEstimate(JSON.parse(estimate));
      }
    }

    setTimeout(function () {
      history("/profile/myOrders");
    }, 4000);
  }, []);

  return (
    <div className="cartProcess">
      <h1 style={{
        fontFamily: "Cairo",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "25px",
        lineHeight: "45px",
        letterSpacing: "-0.3px",
        color: "#331900",
        margin: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>{t("order-completed")}</h1>
      {/* <div className="container-fluid fluid ">
        <div className="cpMiddle-title2">
          <div className="cpPayment">
            <div className="row">
              <div className="col-md-6 cpPayment-div2 ">
                {" "}

              </div>{" "}
              <div className="col-md-6 cpPaymentDone">
                {" "}
                {parseInt(localStorage.getItem("typeId")) === 2 ? (
                  <div>
                    <p className="paymentDone-p1">
                      {" "}
                      {t("process.success")} &#x1F389;{" "}
                    </p>
                    <p className="paymentDone-p2">
                      {" "}
                      {t("process.time_estimate")}{" "}
                      <span> {estimate.timeFrom} </span>
                      {t("process.minute")} {t("process.to")}{" "}
                      <span> {estimate.timeTo} </span> {t("process.minute")}{" "}
                      &#160;
                    </p>
                    <button onClick={handleProceed}>
                      {" "}
                      {t("process.track")}{" "}
                    </button>{" "}
                  </div>
                ) : (
                  <div>
                    <p className="paymentDone-p1"> {t("process.waiting1")} </p>
                    <p className="paymentDone-p2"> {t("process.waiting2")} </p>
                    <button onClick={handleProceed}>
                      {" "}
                      {t("process.track")}{" "}
                    </button>{" "}
                  </div>
                )}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div> */}
    </div>
  );
}

export default CartProcess4;
