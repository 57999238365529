import "../Styles/CartItem.css";
import { useState, useEffect } from "react";
import { RiDeleteBin5Line } from "react-icons/ri";
import { Get_Basket } from "../api-nano";
import { BeatLoader } from "react-spinners";

function CartItem(props) {
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);

  
    // //Edit_Quantity api call takes cart id, meal id and new quantity
    // function handleQuantity(rowId, quantity) {
      
    //   let data = {
    //     rowId: rowId,
    //     quantity: quantity,
    //   };
  
    //   Edit_Quantity(data, token)
    //     .then((response) => {
    //       // setQuan(quantity);
    //       // setTotalPr(quantity * props.price)
    //       console.log(response)
          
    //       // localStorage.removeItem("paymentMethod");
    //       localStorage.removeItem("offer");
    //       localStorage.removeItem("promo"); //remove value of promo code
  
    //       props.setCalcTotal(!props.calcTotal); //triggers api call in (App.js) to rerender cart
    //     })
    //     .catch((err) => {
    //       if (err.response.status === 401) {
    //         //used to change cart icon's current value to 0
  
    //         props.setCartItem(0);
  
    //         localStorage.removeItem("token");
    //         localStorage.removeItem("userPhone");
    //         localStorage.removeItem("userName");
    //         localStorage.removeItem("basket");
    //         //was used to store user's current step in cart process
    //         localStorage.removeItem("process");
  
    //         // history("/login");
    //       }
    //     });
    // }

    useEffect(() => {
      const token = localStorage.getItem("token");
      const fetchOrderDetails = async () => {
        try {
          const response = await Get_Basket(token);
          setOrder(response.data.content);
        } catch (error) {
          console.error('Error fetching order details:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchOrderDetails();
    }, []);


  if (!order || order.length === 0) {
    return <div className="empty-cart"></div>;
  }

  if (loading) {
    return (
      <div className="loader orderDetailsLoader">
        <BeatLoader color={"#ffea13"} loading={loading} size={30} />
      </div>
    );
  }

  return (
    <div className="cart-container">
      {order.map((item, index) => (
        <div key={index} className="cart-item">
          <div className="item-details">
            <h3>{item.name}</h3>
            <p className="item-unit">{item.units_name}</p>
            {item.options && item.options.map((option, optionIndex) => (
              <p key={optionIndex} className="item-option">
                {option.name} : 
                {option.values.map((value, valueIndex) => (
                  <span key={valueIndex}> {value.name}{valueIndex < option.values.length - 1 ? ', ' : ''}</span>
                ))}
              </p>
            ))}
          </div>
          <div className="item-price">{item.price} {props.currency}</div>

          {/* 
          <div className="col-2 cpMiddle-center">
            <button onClick={() => handleQuantity(item.rowId, item.qty + 1)}>
              {" "}
              +{" "}
            </button>{" "} 
          */}
          <div className="item-quantity">{item.qty}</div>
          {/* <button
                onClick={() => item.qty !== 1 ? handleQuantity(item.rowId, item.qty - 1) : null
                }
              >
                {" "}
                -{" "}
              </button>{" "}
              </div>
          */}
          <div className="item-subtotal">{item.subtotal.toFixed(2)} {props.currency}</div>
          <button
            onClick={() => {
              props.setModal(true);
              props.setDelData({ rowId: item.rowId });
            }}
            className="remove-button"
          >
            <RiDeleteBin5Line size={20} />
          </button>
        </div>
      ))}
    </div>
  );
}

export default CartItem;