import "../Styles/SingleProduct.css";
import Footer from "../Components/Footer";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useContext } from "react";
import { single_product } from "../api-nano";
import { BeatLoader } from "react-spinners";
import { generatePath } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CustomerInfo, categories } from "../api-nano";
import MealNotAvail from "../Components/MealNotAvail";
import { CategoryContext } from "../Context/CategoryContext";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
// import { getAnalytics, logEvent } from "firebase/analytics";
import { useLocation } from "react-router-dom";
// import { app } from "../Firebase/firebaseConfig";
import { BackgroundImage } from "react-image-and-background-image-fade";
import { Add_to_cart, Get_Basket, Me } from "../api-nano";
import { useBasketCount } from "../App";
import timthumb from "../Images/timthumb.png";
import { BiSolidHot } from "react-icons/bi";
import { IoWarning } from "react-icons/io5";

function SingleProduct(props) {
  const history = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [meal, setMeal] = useState();
  const [product_options, setProduct_options] = useState();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const { categ, setCateg } = useContext(CategoryContext);
  const [activeCategory, setActiveCategory] = useState(categ); //used uceContext so when user returns from a single prodcut screen the category isn't changed back to first category
  const [category, setCategory] = useState([]);
  const [size, setSize] = useState(0);
  const [noSizes, setNoSizes] = useState(false);
  const [noSizesMsg, setNoSizesMsg] = useState("hhhhh");
  const [sizeErr, setSizeErr] = useState(false);

  const [total, setTotal] = useState(0);
  const [totalCal, setTotalCal] = useState(0);
  const [extras, setExtras] = useState([]);
  const [additionalsRequired, setAdditionalsRequired] = useState();
  const [additionals, setAdditionals] = useState();
  const [chosenSize, setChosenSize] = useState();
  const [chosenAddition, setChosenAddition] = useState(null);
  const [chosenExtras, setChosenExtras] = useState([]);
  const [reommend, setreommend] = useState([]);
  const [quan, setQuan] = useState(1);
  const [phone, setPhone] = useState("");
  const [showingAlert, setShowingAlert] = useState(false);
  const [showingAlert2, setShowingAlert2] = useState(false);
  //   const analytics = getAnalytics(app);
  const [disabled, setDisabled] = useState(false);

  // const [additionalIndex, setAdditionalIndex] = useState(0);

  const [itemsCount, setItemsCount] = useBasketCount();
  var addressId = "";

  const [allPrice, setAllPrice] = useState({
    total: {price: 0},
    0: {price: 0},
    1: {price: 0},
    2: {price: 0}
  });

  const [additionalIndex, setAdditionalIndex] = useState({
    0: { optionIds: [] },
    1: { optionIds: [] },
    2: { optionIds: [] }
  });


  

  //single_product api call takes meal id from url and branchId (set when user chose order type) and gets meal data
  //fetch customer data for analytics and webengage use
  useEffect(() => {
    window.scrollTo(0, 0);
    const token = localStorage.getItem("token");

    categories().then((response) => {
      const data = response.data.data;

      setCategory(data);
    });
    

    single_product(id, localStorage.getItem("branchId"), token)
      .then((response) => {
        const data = response.data;
        console.log("the recent product is", data);
        // setDisabled(
        //   data.sizes[0].isAdditionalRequired
        //     ? !data.sizes[0].additionals[0].canAddToCart
        //     : !data.sizes[0].canAddToCart
        // );
        setMeal(data);

        setProduct_options(data.product_options?.data);

        if(allPrice['total'].price == 0){
          setAllPrice(prevState => {
            const updatedState = { ...prevState };
            
            // updatedState.total = { price: data.prices_units?.data[0].price };
            updatedState.total = { price: data.price };
            
            let xTotal = 0;
            for (const key of Object.keys(updatedState)) {
              xTotal += updatedState[key].price;
            }
        
            setTotal(xTotal);
        
            return updatedState;
          });
        }
        // setAdditionals(data.sizes[0].additionals);
        // setChosenAddition(data.sizes[0].additionals[0]);
        if (data?.prices_units?.data?.length > 0) {
          setChosenSize(data.prices_units?.data[0]);
          setTotal(data.prices_units?.data[0].price);
        } else {
          setChosenSize(null);
          setTotal(data.price);
        }
        setExtras(data?.extras);


        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 401) {
          history("/login");
        } else {
          setNoSizes(true);
          setNoSizesMsg(err.response?.data.error);
        }
      });
  }, [location]);

  //on changing additional total price and calories are updatded
  function calc(price, optionId, optionIndex) {
    setAllPrice(prevState => {
      const updatedState = { ...prevState };
      
      // Get the current option's type (radio or checkbox)
      const optionType = product_options[optionIndex].type;
      
      if (optionType === 'radio') {
        // For radio buttons, replace the price
        updatedState[optionIndex] = { price: price };
      } else {
        // For checkboxes, maintain an array of prices
        const currentPrices = updatedState[optionIndex]?.prices || [];
        const currentPrice = updatedState[optionIndex]?.price || 0;
        
        // Check if the option is being selected or deselected
        const checkbox = document.getElementById(optionId);
        if (checkbox?.checked) {
          // Add price if checked
          updatedState[optionIndex] = { 
            price: currentPrice + price,
            prices: [...currentPrices, price]
          };
        } else {
          // Remove price if unchecked
          updatedState[optionIndex] = { 
            price: currentPrice - price,
            prices: currentPrices.filter(p => p !== price)
          };
        }
      }
  
      // Calculate total
      let xTotal = updatedState.total.price;
      for (const key of Object.keys(updatedState)) {
        if (key !== 'total') {
          xTotal += updatedState[key].price || 0;
        }
      }
      setTotal(xTotal);
  
      return updatedState;
    });
  
    setAdditionalIndex(prevState => {
      const updatedState = { ...prevState };
      
      // Get the current option's type (radio or checkbox)
      const optionType = product_options[optionIndex].type;
      
      if (optionType === 'radio') {
        // For radio buttons, replace the optionId
        updatedState[optionIndex] = { optionIds: [optionId] };
      } else {
        // For checkboxes, maintain an array of selected optionIds
        const currentIds = updatedState[optionIndex]?.optionIds || [];
        const checkbox = document.getElementById(optionId);
        
        if (checkbox?.checked) {
          // Add optionId if checked
          updatedState[optionIndex] = { 
            optionIds: [...currentIds, optionId]
          };
        } else {
          // Remove optionId if unchecked
          updatedState[optionIndex] = { 
            optionIds: currentIds.filter(id => id !== optionId)
          };
        }
      }
      
      return updatedState;
    });
  }

  //on changing size (for products that don't have additionals) total price and calories are updatded
  function Totals(calories, price, data, index) {
    // setAdditionals(data.additionals);
    // setAdditionalsRequired(data.isAdditionalRequired);
    setSize(index);
    setTotal(price);
    // setTotalCal(calories);
    setChosenSize(data);
  }

  //on changing size (for products that have additionals) total price, calories, displayed additionals and displayed extras are updatded
  //resets chosen addition to first choice in additionals array
  function handleChange(index) {
    // var elements = document.getElementsByClassName("addition");
    setSize(index);
    // setAdditionals(meal.prices_units[index]?.additionals);
    setChosenSize(meal.prices_units?.data[index]);



    setAllPrice(prevState => {
      const updatedState = { ...prevState };
      
      updatedState.total = { price: meal.prices_units?.data[index].price };
      
      let xTotal = 0;
      for (const key of Object.keys(updatedState)) {
        xTotal += updatedState[key].price;
        // console.log(updatedState[key], updatedState[key].price)
      }
  
      setTotal(xTotal);
  
      return updatedState;
    });

    // setTotal(meal.prices_units?.data[index].price);
    // setTotalCal(meal.prices_units[index].calories);
    // for (var i = 0; i < elements.length; i++) {
    //   if (i == 0) {
    //     elements[i].checked = false;
    //   }
    // }
    // setAdditionalIndex(null);
    // setChosenAddition(null);
    // setDisabled(!meal.sizes[index].canAddToCart);
    // }
  }

  //on changing extras loop through extras and push checked items only to array
  function handleExtras() {
    var checkboxes = document.getElementsByClassName("extras");
    var arr = [];

    for (var i = 0, max = checkboxes.length; i < max; i += 1) {
      if (checkboxes[i].type === "checkbox" && checkboxes[i].checked) {
        arr.push(parseInt(checkboxes[i].value));
      }
    }
    setChosenExtras(arr);
  }

  //on clicking on item from recommendations id in url is changed and screen is rerendered
  function changePath(id) {
    history(
      generatePath("/meals/:id", {
        id,
      })
    );
  }

  //on clicking aff to cart if user is not logged in they will be redirected to /login
  //  Add_to_cart api call takes data and user token
  //user is then redirected to /menu (MainMenu component found in /Pages)
  // Todo: Handle a meal option and add all options to API
  async function handleAdd() {
    const token = window.localStorage.getItem("token");
  
    if (token) {
      if (localStorage.getItem("typeId") == 2) {
        addressId = localStorage.getItem("addressId");
      }
      
      let data = {
        menuId: meal.id,
        quantity: quan,
      };
  
      // Handle units if meal has units
      if (meal.is_units) {
        data = {
          ...data,
          units_id: chosenSize?.units_id,
          units_name: chosenSize?.units_name,
          quantity: quan,
          price: chosenSize?.price,
        };
      }
  
      // Handle product options (both radio and checkbox)
      if (product_options && product_options.length > 0) {
        const options = {};
  
        product_options.forEach((option, index) => {
          // Get selected option IDs for this option group
          const selectedOptionIds = additionalIndex[index]?.optionIds || [];
          
          if (selectedOptionIds.length > 0) {
            // Find the selected values for this option
            const selectedValues = option.products_options_values?.data
              .filter(value => selectedOptionIds.includes(value.id))
              .reduce((acc, value) => {
                acc[value.id] = {
                  id: value.id,
                  name: value.value,
                  price: value.price // Include price if needed
                };
                return acc;
              }, {});
  
            // Add to options object if we have selected values
            if (Object.keys(selectedValues).length > 0) {
              options[option.id] = {
                id: option.id,
                name: option.name,
                values: selectedValues
              };
            }
          }
        });
  
        // Only add options to data if there are actually selected options
        if (Object.keys(options).length > 0) {
          data = { ...data, options };
        }
      }
  
      console.log("Data to be sent:", data);
  
      try {
        const response = await Add_to_cart(data, token);
        console.log("response ", response);
        setShowingAlert(true);
        setItemsCount(response?.data?.data?.count);
        props.setCartChange(!props.cartChange);
        
        // Clear local storage items
        localStorage.removeItem("paymentMethod");
        localStorage.removeItem("promo");
        localStorage.removeItem("offer");
        
        // Redirect after delay
        setTimeout(() => {
          history("/menu");
        }, 1000);
      } catch (error) {
        console.error("Error adding to cart:", error);
        setShowingAlert2(true);
        setTimeout(() => {
          setShowingAlert2(false);
        }, 5000);
      }
    } else {
      history("/login");
    }
  }

  function changeCateg(id) {
    setCateg(id);
    history("/menu");
  }


  return (
    <div>
      {" "}
      {noSizes ? <MealNotAvail noSizesMsg={"noSizesMsg"} /> : null}{" "}
      {loading ? (
        <div className="loader">
          <BeatLoader color={"#ffea13"} loading={loading} size={30} />
        </div>
      ) : (
        <div>
          <div className="container-all container-fluid fluid ">

          <div
            style={{
              marginTop: "2em",
            }}
            className="spNav fluid"
          >
            <p className="spNav">
              {" "}
              <Link to="/menu"> {t("meals.menu")} </Link> {" "}
              {meal.category} <span> / </span> <span> {meal.name} </span>{" "}
            </p>{" "}
          </div>{" "}


            <div
              style={{
                marginBottom: "2em",
              }}
              className="spCategories"
            >
              <div
                className={
                  i18n.language == "ar"
                    ? "mmSideNav "
                    : "mmSideNavEn mmSideNavEn-mobile "
                }
              >
                <p className="mmsideNav-title"> {t("party.categories")} </p>{" "}
                <div className="categoryScroll">
                  {" "}
                  {category &&
                    category.map((data, index) => (
                      <div
                        onClick={() => changeCateg(data.id)}
                        key={index}
                        className={
                          activeCategory == data.id
                            ? "mmsideNav-link mmsideNav-linkActive"
                            : "mmsideNav-link"
                        }
                      >
                        <img
                          src={data.image?.thumb ?? timthumb}
                          alt="chicken"
                        />
                        <p> {data.name} </p>{" "}
                      </div>
                    ))}{" "}
                </div>
              </div>{" "}
            </div>
            <div className="row">
              <div className="col-lg-6  spImage">
                <div
                  className="pImage"
                  style={{
                    backgroundImage: `url(${meal.image?.original})`,
                  }}
                >
                  <div className="spDetailContainer">
                    <div className="spDetail" >
                      <div className="aaas">
                        <p className="sp-p2"> {meal.name} </p>{" "}
                        <p className="sp-p3"> {meal.short_description} </p>{" "}
                        {meal.calories > 0 && 
                          <p className="sp-p-calories"> 
                            <BiSolidHot style={{ fontSize: `medium` }} /> {" "}
                            {meal.calories} {t('party.calories')}
                          </p>
                        }
                        {/* {meal.indication && 
                          <p className="sp-p-indication"> 
                            <IoWarning style={{ fontSize: `medium` }} /> {" "}
                            {meal.indication} {t('party.indication')}
                          </p>
                        } */}
                      </div>{" "}
                      <p className="sp-p1">
                        {" "}
                        {total * quan + " "} {"ريال"}{" "}
                      </p>{" "}
                      {meal.indication && 
                        <p className="sp-p-indication"> 
                          <IoWarning style={{ fontSize: `medium`, color: "#955601" }} /> {" "}
                          <span style={{color: "#955601"}}>{t('party.indication')}:</span> {meal.indication}
                        </p>
                      }
                      </div>
                  </div>
                </div>{" "}
              </div>{" "}
              <form 
                className="col-lg-6 spDetails" 
                onSubmit={(e) => {
                  e.preventDefault();
                  handleAdd()
                  }}>
                <div className="spData">
                  <div>
                    {" "}
                    <p> {t("meals.sizes")} </p>{" "}
                  </div>
                  <div className="row">
                    {meal?.prices_units?.data?.map((data, index) => (
                      <div className="col-12" key={index}>
                        <div className="additionals">
                          {" "}
                          <div>
                            <input
                              onChange={() => handleChange(index)}
                              type="radio"
                              className="checkBox addition"
                              id="html1"
                              name="option"
                              value="HTML"
                              defaultChecked={index === 0}
                            />
                            <label htmlFor="html"> {data.units_name} </label>
                          </div>
                          <div className="price-calories">
                            {data.calories > 0 && 
                              <p className="p-calories"> 
                                <BiSolidHot style={{ fontSize: `medium` }} /> {" "}
                                {data.calories} {t('party.calories')}
                              </p>
                            }
                            <p>{data.price} {"ريال"}</p>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>
                    ))}
                  </div>{" "}
                </div>
                
                {/* Todo: Display all options of product  */}
                {product_options  ? (
                  <div>
                    {product_options.map((option, optionIndex) => (
                      <div className="spData" key={optionIndex}>
                        <p>{option.name}</p>
                        <div className="row">
                          {option.products_options_values?.data?.map((data, index) => (
                            <div className="col-12" key={index}>
                              <div className="additionals">
                                <div>
                                  <input
                                    // required
                                    onChange={(e) => calc( data.price, data.id, optionIndex)}
                                    className="checkBox addition"
                                    type={option.type === 'radio' ? 'radio' : 'checkbox'}
                                    // type={'radio'}
                                    // id={`option-${optionIndex}-${index}`}
                                    id={data.id}
                                    name={`mealAdditional-${optionIndex}`}
                                    value={data?.value}
                                    
                                  />
                                  <label htmlFor={data.id}>{data.value}</label>
                                  {/* <label htmlFor={`option-${optionIndex}-${index}`}>{data.value}</label> */}
                                </div>
                                <p>{data.price} ريال</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}

              {/* 
                {product_options ? (
                  <div className="spData">
                    {" "}
                    <p> {product_options[0]?.name} </p>{" "}
                    <div className="row">
                      {product_options[0]?.products_options_values?.data?.map(
                        (data, index) => (
                          <div className="col-12" key={index}>
                            <div className="additionals">
                              {" "}
                              <div>
                                <input
                                  // onClick={
                                  //   additionalsRequired
                                  //     ? null
                                  //     : () => handleUncheck(index)
                                  // }
                                  onChange={(e) =>
                                    calc(null, data.price, index)
                                  }
                                  className="checkBox addition"
                                  type="radio"
                                  id="html1"
                                  name="mealAdditional"
                                  value={data?.value}
                                  defaultChecked={index === 0}
                                />
                                <label htmlFor="html"> {data.value} </label>{" "}
                              </div>
                              <p>
                                {" "}
                                {data.price} {"ريال"}{" "}
                              </p>{" "}
                            </div>{" "}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                ) : null}{" "} 
                */}

                {!extras?.length == 0 ? (
                  <div className="spData">
                    <div>
                      {" "}
                      <p> {t("meals.extras")} </p>{" "}
                    </div>
                    <div className="row">
                      {extras.map((data, index) => (
                        <div className="col-md-3   col-sm-4 col-6" key={index}>
                          <input
                            onChange={() => handleExtras()}
                            value={data.id}
                            className=" additions extras"
                            type="checkbox"
                            id="html1"
                          />

                          <label htmlFor="html"> {data?.name} </label>
                        </div>
                      ))}
                    </div>{" "}
                  </div>
                ) : null}
                {/* <div className="spData  calories">
                  <div>
                    <p> {t("meals.calories1")} </p>{" "}
                  </div>{" "}
                  <div>
                    <p className="totalCal">
                      {" "}
                      {totalCal + "  "} {t("meals.calories2")}{" "}
                    </p>
                  </div>{" "}
                  <div></div>{" "}
                </div>{" "} */}
                <p className="btnTitle"> {t("meals.quantity")} </p>{" "}
                <div
                  className="spButtons"
                  style={{
                    position: "relative",
                  }}
                >
                  <div
                    className={
                      i18n.language == "ar"
                        ? "spQuantity"
                        : "spQuantity spQuantityEn"
                    }
                  >
                    <div>
                      <button
                        type="button"
                        onClick={() => setQuan(quan + 1)}
                        className="spQuantity-btn1"
                      >
                        {" "}
                        +{" "}
                      </button>{" "}
                      <p> {quan} </p>{" "}
                      <button
                        type="button"
                        onClick={() => (quan !== 1 ? setQuan(quan - 1) : null)}
                        className="spQuantity-btn2"
                      >
                        {" "}
                        -{" "}
                      </button>
                    </div>{" "}
                  </div>

                  {/* Popup Alert */}
                  <div
                    className={
                      showingAlert ? "addAlert" : " addAlert alert-hidden"
                    }
                  >
                    <div className="row">
                      <div className="col">
                        <div
                          className="alertImg"
                          style={{
                            backgroundImage: `url(${meal.image})`,
                          }}
                        ></div>
                      </div>
                      <div className="col">
                        <div
                          className={
                            i18n.language == "ar"
                              ? "vAlignText"
                              : "vAlignText enAlert"
                          }
                        >
                          <p>
                            {" "}
                            {t("meals.added1") + " "} <span> {meal.name} </span>
                          </p>
                          <p> {t("meals.added2")} </p>
                        </div>{" "}
                      </div>{" "}
                    </div>
                  </div>

                  <div
                    className={
                      showingAlert2 ? "addAlert" : " addAlert alert-hidden"
                    }
                  >
                    <div className="row">
                      <div className="col">
                        <div
                          className="alertImg"
                          style={{
                            backgroundImage: `url(${meal.image})`,
                          }}
                        ></div>
                      </div>
                      <div className="col">
                        <div
                          className={
                            i18n.language == "ar"
                              ? "vAlignText"
                              : "vAlignText enAlert"
                          }
                        >
                          <p>
                            {t('meals.addStockCount')}
                            <span>{meal.max_qty_in_stock}</span>.
                          </p>
                        </div>{" "}
                      </div>{" "}
                    </div>
                  </div>

                  <button
                    // disabled={disabled}
                    // onClick={handleAdd}
                    className="spAdd"
                    type="submit"
                  >
                    {" "}
                    {t("meals.button")}{" "}
                    <span>
                      {" "}
                      {/* Todo: round the price to two decimal */}
                      {parseFloat((total * quan).toFixed(2)) + " "} {"ريال"}{" "}
                    </span>
                  </button>
                </div>{" "}
              </form>{" "} 
            </div>{" "}
          </div>
          <div
            style={{
              marginBottom: "5em",
            }}
          >
            {" "}
          </div>
        </div>
      )}{" "}
      {/* <Footer /> */}
    </div>
  );
}

export default SingleProduct;
