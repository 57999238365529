import "../Styles/Address.css";

import { HiOutlineLocationMarker } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { near_branches } from "../api-nano";

function MyAddress(props) {
  const { t, i18n } = useTranslation();
  const history = useNavigate();


  function nearBranches() {
  near_branches(props.latitude, props.longitude).then((response) => {
    let data = {
      arAddress: props.arAddress,
      enAddress: props.enAddress,
      latitude: props.latitude,
      longitude: props.longitude,
      id: props.id,
    };

    localStorage.setItem("addressData", JSON.stringify(data));

    // Sort the branches by distance before storing
    const sortedBranches = response?.data?.data.sort((a, b) => {
      // Assuming each branch has a 'distance' property
      return a.distance - b.distance;
    });

    localStorage.setItem("nearBranches", JSON.stringify(sortedBranches));
    localStorage.setItem("addressId", props.id);
    localStorage.setItem("addressLat", props.latitude);
    localStorage.setItem("addressLong", props.longitude);
    localStorage.setItem("typeId", 2);
    console.log(response);

    if (sortedBranches.length === 0) {
      props.setModal(true);
    } else {
      history("/branch");
    }
  });
}

  return (
    <div
      className="col-12"
      style={
        i18n.language == "ar"
          ? null
          : {
              textAlign: "left",
            }
      }
    >
      <div className="myAddress">
        <div>
          <p className="addressName"> {props.title} </p>{" "}
          <div
            className="addressLoc"
            style={
              i18n.language == "ar"
                ? {
                    direction: "rtl",
                  }
                : null
            }
          >
            <HiOutlineLocationMarker
              size={30}
              style={{
                color: "#ffea13",
              }}
            />{" "}
            <p className="location"> {props.address} </p>
          </div>{" "}
        </div>{" "}
        <div className="chooseAddress">
          {" "}
          <button onClick={() => nearBranches()}>
            {" "}
            {t("address.choose")}{" "}
          </button>
        </div>
      </div>
    </div>
  );
}

export default MyAddress;
