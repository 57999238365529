import "../Styles/CompanySales.css";

import { HiOutlineCash } from "react-icons/hi";
import { BsPeople } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function SalesComp(props) {
  const { t, i18n } = useTranslation();

  let history = useNavigate();

  //on clicking on a menu user is redirected to /companies-menu screen (CompaniesMenu component found in /Pages) with chosen menu id as params
  const handleProceed = (id) => {
    history(
      generatePath("/companies-menu/:id", {
        id,
      })
    );
  };
  return (
    <div className="col-lg-4 col-sm-6">
      <div className="menuContainer">
        <div
          style={{
            minWidth: "auto",
          }}
          className=" medyafMenu"
        >
          <div
            className="bckg "
            style={{
              backgroundImage: `url(${props.image})`,
            }}
          ></div>{" "}
          <div className="paddingMenu">
            <div
              style={
                i18n.language == "ar"
                  ? {
                      textAlign: "right",
                    }
                  : {
                      textAlign: "left",
                    }
              }
            >
              <p
                style={{
                  color: "#633235",
                }}
              >
                {" "}
                {props.name}{" "}
              </p>
            </div>
            <div className="medyafDetails">
              <p>
                {" "}
                <HiOutlineCash
                  size={20}
                  className={i18n.language == "ar" ? "mmIconsAr" : "mmIcon"}
                />{" "}
                {props.price} {t("nav.SAR")}
              </p>
              <p>
                {" "}
                <BsPeople
                  size={20}
                  className={i18n.language == "ar" ? "mmIconsAr2" : "mmIcon2"}
                />
                {t("companySales.min_persons")} {props.min}{" "}
                {t("companySales.person")}
              </p>{" "}
            </div>
          </div>{" "}
          <button onClick={() => handleProceed(props.id)}>
            {" "}
            {t("companySales.openMenu")}{" "}
          </button>{" "}
        </div>
      </div>
    </div>
  );
}

export default SalesComp;
