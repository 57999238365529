import axios from "axios";

export default axios.create({
  baseURL: "https://dev.alwajiha-rest.com/api/v1",
  // baseURL: "https://dev.rydan-res.com/api/v1",
  // baseURL: "https://apis.alromansiah.com/api/v2",
  headers: {
    "Content-type": "application/json",
    "Accept-Language": JSON.parse(localStorage.getItem("LANG")),
    // "Content-Type": "multipart/form-data",
    // "Access-Control-Allow-Origin": "*",
  },
});
