import "../Styles/UserProfile.css";
import { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useFormik } from "formik";
import { BiEdit } from "react-icons/bi";
import Footer from "../Components/Footer";
import { CustomerInfo, updateCustomer } from "../api-nano";
import ProfileChangePass from "./ProfileChangePass";
import UserNav from "../Components/UserNav";
import Logout from "../Components/Logout";
import { BeatLoader } from "react-spinners";
import pp from "../Images/defaultImg.jpg";
import { useNavigate } from "react-router-dom";
import { Me } from "../api-nano";

function PersonalData(props) {
  let history = useNavigate();
  const imgRef = useRef();
  const [preview, setPreview] = useState();
  const [image, setImage] = useState();
  const [chagePass, setChangePass] = useState(false);
  const [edit, setEdit] = useState(false);
  const [photo, setPhoto] = useState();
  const { t, i18n } = useTranslation();
  const [msgEn, setMsgEn] = useState();
  const [msgAr, setMsgAr] = useState();
  const [errorMsgModel, setErrorMsgModel] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);

  let phoneRegEx =
    /^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;
  let emailRegx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const Schema = yup.object().shape({
    phone: yup.string().required(t("validation.phone_required")),
    // .matches(phoneRegEx, {
    //   message: t("validation.phone_not_valid"),
    // })
    name: yup.string().required(t("validation.name_required")),
    message: yup.string().required(t("validation.message_required")),
    email: yup
      .string()
      .required(t("validation.email_required"))
      .matches(emailRegx, {
        message: t("validation.invalid_email"),
      }),
  });

  const formik = useFormik({
    initialValues: {
      name: name,
      phone: phone,
    },
    validationSchema: Schema,
  });

  const phoneRef = useRef(phone);

  const nameRef = useRef(name);

  //fetch  user data
  useEffect(() => {
    //for image formate to be displayable
    if (preview) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(preview);
    }

    const token = localStorage.getItem("token");

    Me(token)
      .then((response) => {
        console.log("me resp", response);
        setImage(response.data.avater);
        setPhoto(response.data.avater);
        setName(response.data.name);
        setPhone(response.data.mobile);
        formik.values.phone = response.data.mobile;
        formik.values.name = response.data.name;
        setLoading(false);
      })
      .catch((err) => {});
  }, [edit]);

  //resets changes made in user data
  function cancleEdit() {
    const token = localStorage.getItem("token");

    // Me(token)
    //   .then((response) => {
    //     setImage(photo);
    //     document.getElementById("name").value = name;
    //     document.getElementById("phone").value = phone;
    //   })
    //   .catch((err) => {
    //     if (err.response.status === 401) {
    //       //used to change cart icon's current value to 0

    //       props.setCartItem(0);

    //       localStorage.removeItem("token");
    //       localStorage.removeItem("userPhone");
    //       localStorage.removeItem("userName");
    //       localStorage.removeItem("basket");
    //       //was used to store user's current step in cart process
    //       localStorage.removeItem("process");

    //       history("/login");
    //     }
    //   });
  }

  //for image formate to be displayable rerenders onChanging image input
  useEffect(() => {
    if (preview) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(preview);
    }
    setLoading(false);
  }, [preview]);

  //each input is handled separately
  // if(preview) => *if image got changed* ()=> editPicture api is called and image is updated

  //if phone number got changed updateCustomer api is called then user is sent an otp and redirected to "/verify-phone" (UpdatePhone component found in /Pages)
  //if name number got changed updateCustomer api is called and user name is updated

  function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();
    const token = localStorage.getItem("token");
    if (preview) {
      const formData = new FormData();
      formData.append("photo", preview);
      formData.append(" _method", "PUT");

      // editPicture(formData, token)
      //   .then((response) => {
      //     setEdit(!edit); //to rerender usernav to update changed data
      //     setLoading(false);
      //   })
      //   .catch(function (err) {
      //     if (err.response.status === 401) {
      //       //used to change cart icon's current value to 0

      //       props.setCartItem(0);

      //       localStorage.removeItem("token");
      //       localStorage.removeItem("userPhone");
      //       localStorage.removeItem("userName");
      //       localStorage.removeItem("basket");
      //       //was used to store user's current step in cart process
      //       localStorage.removeItem("process");

      //       history("/login");
      //     }
      //     setLoading(false);
      //   });
    }

    const values = {
      phone: phoneRef.current.value,
    };
    if (phone !== phoneRef.current.value) {
      updateCustomer(values, token)
        .then((response) => {
          history("/verify-phone");
          localStorage.setItem("newPhone", values.phone);
          /* CustomerInfo(token).then((response) => {
                    localStorage.setItem("userName",response.data.data.name);
                      localStorage.setItem("userPhone",values.phone);
                   // window.location.reload();
                   setLoading(false)  
                    }).catch(function (error) {
                          if (error.response.status === 422) {
                                       
                            setMsgAr("هذا الرقم مسجل من قبل");
                               setMsgEn("This phone already exists");
                                 setErrorMsgModel(true);
                                 setLoading(false)  
                                     }   else {
                                          console.log(error)
                                          setLoading(false)  
                                     }
                                 
                                 })*/
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            //used to change cart icon's current value to 0

            props.setCartItem(0);

            localStorage.removeItem("token");
            localStorage.removeItem("userPhone");
            localStorage.removeItem("userName");
            localStorage.removeItem("basket");
            //was used to store user's current step in cart process
            localStorage.removeItem("process");

            history("/login");
          } else if (error.response.status === 422) {
            setMsgAr("هذا الرقم مسجل من قبل");
            setMsgEn("This phone already exists");
            setErrorMsgModel(true);
            setLoading(false);
          } else {
            console.log(error);
            setLoading(false);
          }
        });
    } else if (name !== nameRef.current.value) {
      let valuesUpdated = {
        name: nameRef.current.value,
      };
      updateCustomer(valuesUpdated, token)
        .then((response) => {
          CustomerInfo(token)
            .then((response) => {
              localStorage.setItem("userName", response.data.data.name);

              // window.location.reload();
              setEdit(!edit); //to rerender usernav to update changed data
              setLoading(false);
            })
            .catch(function (error) {
              if (error.response.status === 401) {
                //used to change cart icon's current value to 0

                props.setCartItem(0);

                localStorage.removeItem("token");
                localStorage.removeItem("userPhone");
                localStorage.removeItem("userName");
                localStorage.removeItem("basket");
                //was used to store user's current step in cart process
                localStorage.removeItem("process");

                history("/login");
              } else if (error.response.status === 422) {
                setMsgAr("هذا الرقم مسجل من قبل");
                setMsgEn("This phone already exists");
                setErrorMsgModel(true);
                setLoading(false);
              } else {
                console.log(error);
                setLoading(false);
              }
            });
        })
        .catch((err) => {
          if (err.response.status === 401) {
            //used to change cart icon's current value to 0

            props.setCartItem(0);

            localStorage.removeItem("token");
            localStorage.removeItem("userPhone");
            localStorage.removeItem("userName");
            localStorage.removeItem("basket");
            //was used to store user's current step in cart process
            localStorage.removeItem("process");

            history("/login");
          }
        });
    }
  }

  return (
    <div>
      {" "}
      {modal ? (
        <Logout setModal={setModal} setCartItem={props.setCartItem} />
      ) : null}{" "}
      <div className="userProfile">
        <UserNav setModal={setModal} nav={5} edit={edit} />{" "}
        {loading ? (
          <div className="loader profileLoader">
            <BeatLoader color={"#ffea13"} loading={loading} size={30} />
          </div>
        ) : chagePass ? (
          <ProfileChangePass
            setChangePass={setChangePass}
            setCartItem={props.setCartItem}
          />
        ) : (
          <div className="container-profile-header aboutusBody profileBody container-fluid fluid">
            <p className="upTable-title"> {t("profile.personal")} </p>
            <div className="userData">
              <form onSubmit={(e) => handleSubmit(e)} name="personalData">
                <div className="upTop">
                  <div
                    style={{
                      position: "relative",
                    }}
                    className="upImageBorder"
                  >
                    <button
                      type="button"
                      className="editImage"
                      onClick={() => imgRef.current.click()}
                    >
                      {" "}
                      <BiEdit size={30} />
                    </button>
                    <input
                      type="file"
                      style={{
                        display: "none",
                      }}
                      ref={imgRef}
                      onChange={(e) => {
                        console.log("change");
                        const file = e.target.files[0];
                        if (file) {
                          setPreview(file);
                        }
                      }}
                    />{" "}
                    <div
                      className="profileImage "
                      style={{
                        backgroundImage: `url(${image ? image : pp})`,
                      }}
                    >
                      {" "}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="inputDiv">
                    <label> {t("Login.yourName")} </label>
                    <input
                      type="text"
                      value={formik.values.name}
                      id="name"
                      name="name"
                      ref={nameRef}
                      required={true}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={
                        formik.errors.name && formik.touched.name
                          ? "invalid"
                          : null
                      }
                    />{" "}
                    <span>
                      {" "}
                      {formik.errors.name && formik.touched.name
                        ? formik.errors.name
                        : null}{" "}
                    </span>{" "}
                  </div>
                </div>
                <div>
                  <div className="inputDiv">
                    <label> {t("Login.yourPhone")} </label>{" "}
                    <input
                      type="text"
                      value={formik.values.phone}
                      id="phone"
                      name="phone"
                      ref={phoneRef}
                      required={true}
                      onFocus={() => setErrorMsgModel(false)}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={
                        (formik.errors.phone && formik.touched.phone) ||
                        errorMsgModel
                          ? "invalid"
                          : null
                      }
                    />{" "}
                    <span>
                      {" "}
                      {formik.errors.phone && formik.touched.phone
                        ? formik.errors.phone
                        : null}{" "}
                    </span>{" "}
                    {i18n.language == "ar" ? (
                      <span> {errorMsgModel ? msgAr : null} </span>
                    ) : (
                      <span> {errorMsgModel ? msgEn : null} </span>
                    )}{" "}
                  </div>
                </div>
                <div></div>
                <div></div> <div></div>{" "}
                <div>
                  <div className="inputDiv">
                    <button
                      type="button"
                      onClick={() => setChangePass(true)}
                      className="changePass"
                    >
                      {" "}
                      <p> {t("profile.change_pass")} </p>
                    </button>
                  </div>
                </div>
                <div className="formBtns">
                  <button type="submit" className="dataEdit">
                    {" "}
                    {t("profile.save")}{" "}
                  </button>{" "}
                  {/* <span> </span>{" "}
                  <button
                    type="button"
                    className="cancelEdit"
                    onClick={cancleEdit}
                  >
                    {" "}
                    {t("profile.cancel")}{" "}
                  </button> */}
                </div>
              </form>
            </div>{" "}
          </div>
        )}
      </div>{" "}
      {/* <Footer /> */}
    </div>
  );
}

export default PersonalData;
