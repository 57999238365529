import "../Styles/Address.css";
// import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { useMemo, useRef, useCallback } from "react";
import axios from "axios";
import homeLocation from "../Images/homeLocation.svg";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import Autocomplete from "react-google-autocomplete";
import { address, near_branches } from "../api-nano";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import SuccessModal from "../Components/SuccessModal";
import { BsGeoAlt, BsSearch } from "react-icons/bs";
import { MapContainer, TileLayer, Marker, useMapEvents, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L, { Icon } from 'leaflet';


const customIcon = new Icon({
  // iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  iconUrl: homeLocation,
  iconSize: [35, 51],
  iconAnchor: [12, 41],
});

function LocationMarker({ position, setPosition, handleLocationChange }) {
  const map = useMapEvents({
    click(e) {
      const newPosition = e.latlng;
      setPosition(newPosition);
      handleLocationChange(newPosition.lat, newPosition.lng);
      map.flyTo(newPosition, map.getZoom());
    },
  });

  return position === null ? null : (
    <Marker position={position} icon={customIcon}>
      <Popup>Selected Location</Popup>
    </Marker>
  );
}

function CurrentLocationButton({ setPosition, handleLocationChange }) {
  const map = useMap();

  const handleClick = useCallback(() => {
    map.locate().on("locationfound", function (e) {
      setPosition(e.latlng);
      handleLocationChange(e.latlng.lat, e.latlng.lng);
      map.flyTo(e.latlng, map.getZoom());
    });
  }, [map, setPosition, handleLocationChange]);

  return (
    <button
      onClick={handleClick}
      className="get-location-btn"
      title="Get current location"
    >
      <BsGeoAlt />
    </button>
  );
}


function AddAddress(props) {
  const [modal, setModal] = useState(false);

  // const [position, setPosition] = useState({
  //   lat: props.loc.lat || 51.505,
  //   lng: props.loc.lng || -0.09,
  // });

  const [position, setPosition] = useState({
    lat: 26.396186221528705,
    lng: 50.04971366113109,
  });

  const [arAddress, setArAddress] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  // const Schema = yup.object().shape({
  //   name: yup.string().required(t("validation.title_required")),
  //   notes: yup.string(),
  // });

  // const formik = useFormik({
  //   initialValues: {
  //     name: "",
  //     notes: "",
  //   },
  //   validationSchema: Schema,
  //   onSubmit,
  // });
  




  //API that reverses lat and lng to english and arabic names of the location
  //uses geocode to 1- set current address 2- check if in bahrain 3- check if outside saudi/bahrain
  ///if in bahrain (cntry=="BH") more input fields are displayed for user
  // //if outside  saudi/bahrain submit button is disabled
  // useEffect(() => {
  //   axios
  //     .get(
  //       "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
  //         position.lat +
  //         "," +
  //         position.lng +
  //         "&language=ar&key=AIzaSyAmB4A2hOFAVj4Dr_QcKlWXFE7okQ5SSbg"
  //     )
  //     .then((response) => {
  //       // if (
  //       //   response.data.results[0].address_components[0].short_name !== "YE" &&
  //       //   response.data.results[0].address_components[0].short_name !== "SA"
  //       // ) {
  //       // setOutside(true);
  //       // } else {
  //       setOutside(false);
  //       // }
  //       // console.log("kkkkkkkk", response.data);
  //       setCntry(response.data.results[0].address_components[0].short_name);
  //       setArAddress(response.data.results[0].formatted_address);
  //       console.log("kkkkkkkk", response.data.results[0].formatted_address);
  //     });
  // }, []);

  // const { isLoaded } = useLoadScript({
  //   googleMapsApiKey: "AIzaSyDPInNXxJ-Drq0RXS45apFP3snnhX5VTpw",

  //   libraries: ["places"],
  // });
  const markerRef = useRef();
  

  const Schema = yup.object().shape({
    name: yup.string().required("validation.title_required"),
    place: yup.string().required("validation.place_required"),
    compound: yup.string().required("validation.compound_required"),
    street: yup.string().required("validation.street_required"),

    building: yup.string().required("validation.building_required"),

    apartment: yup.string().required("validation.apartment_required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      place: "",
      compound: "",
      street: "",
      building: "",
      apartment: "",
    },
    validationSchema: Schema,
    onSubmit,
  });

  const nameRef = useRef();
  const notesRef = useRef();
  const compoundRef = useRef();
  const streetRef = useRef();
  const buildingRef = useRef();
  const apartmentRef = useRef();

  //const options = useMemo(() => countryList().getData(), [])
  const history = useNavigate();

  //const [value, setValue] = useState('')
  //const [showingAlert, setShowingAlert] = useState(false)
  //const [map,setMap]=useState(null);
  const [enAddress, setEnAddress] = useState(null);
  //const [empty,setEmpty]=useState(false);
  //const [empty2,setEmpty2]=useState(false);
  const [cntry, setCntry] = useState(props.countryCode);
  const [outside, setOutside] = useState(false);
  const [defalutLocation, setDefaultLocation] = useState("Amsterdam");
  //displays loading if map is not yet loaded
  // if (!isLoaded) {
  //   return (
  //     <div className="loader">
  //       <BeatLoader color={"#ffea13"} loading={!isLoaded} size={30} />
  //     </div>
  //   );
  // }

  //API that reverses lat and lng to english and arabic names of the location
  //uses geocode to 1- set current address 2- check if in bahrain 3- check if outside saudi/bahrain
  ///if in bahrain (cntry=="BH") more input fields are displayed for user
  //if outside  saudi/bahrain submit button is disabled
  function nearBranches() {
    near_branches(props.latitude, props.longitude).then((response) => {
      let data = {
        arAddress: props.arAddress,
        enAddress: props.enAddress,
        latitude: props.latitude,
        longitude: props.longitude,
        id: props.id,
      };
  
      localStorage.setItem("addressData", JSON.stringify(data));
  
      // Sort the branches by distance before storing
      const sortedBranches = response?.data?.data.sort((a, b) => {
        // Assuming each branch has a 'distance' property
        return a.distance - b.distance;
      });
  
      localStorage.setItem("nearBranches", JSON.stringify(sortedBranches));
      localStorage.setItem("addressId", props.id);
      localStorage.setItem("addressLat", props.latitude);
      localStorage.setItem("addressLong", props.longitude);
      localStorage.setItem("typeId", 2);
      console.log(response);
  
      if (sortedBranches.length === 0) {
        props.setModal(true);
      } else {
        history("/order-now");
      }
    });
  }
  
  function handleLocationChange(lat, lng) {
    axios
      .get(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&accept-language=ar`
      )
      .then((response) => {
        if (response.data && response.data.display_name) {
          setArAddress(response.data.display_name);
          console.log("The address is", response.data.display_name);
        } else {
          console.error("Unable to fetch address");
          setArAddress("Address not found");
        }
      })
      .catch((error) => {
        console.error("Error fetching address:", error);
        setArAddress("Error fetching address");
      });
  }

  

  //submits values (some values depend on selected country)
  //address api call saves the address
  //branchesNear api call takes address lat and lng and returns nearbranches
  //and will store near branches (along with address data *to be used later in payment process*), if there are no near branches user is alerted else user is redirect to /branches screen (FromBranch component found in /Pages)
  function onSubmit(e) {
    e.preventDefault();
    setSubmitLoading(true);

    const token = localStorage.getItem("token");
    /*
        if(!value){
          setEmpty(true)
          setSubmitLoading(false)
        }*/

    // const data = {
    //   address_1: arAddress,
    //   address_2: "شارع تعز 1",
    //   street_name: null,
    //   street_number: null,
    //   latitude: position.lat,
    //   longitude: position.lng,
    //   radius: 0,
    //   // city: null,
    //   // zip: null,
    //   // postcode: null,
    //   // country_long: null,
    //   country_id: localStorage.getItem("country"),
    //   state_id: "3",
    //   // directorate_id: null,
    //   // formataddress: null,
    //   // vicinity: null,
    //   // geo_components: null,
    //   // addressable_type: "RainLabUserModelsUser",
    //   // addressable_id: 543,
    // };

    const data = {
      addressable_id: localStorage.getItem("userId"), // Adjust this based on your user identification method
      type: "home", // Adjust this based on your address type options
      address_1: arAddress,
      address_2: notesRef.current.value, // Using the notes field as address_2
      country_id: localStorage.getItem("country"),
      state_id: "3", // Make sure this is the correct state ID
      longitude: position.lng,
      latitude: position.lat,
      street_name: "", // Add if available
      street_number: "", // Add if available
    };

    let values = {
      address_1: arAddress,
      enAddress: enAddress,
      latitude: position.lat,
      longitude: position.lng,
      title: nameRef.current.value,
      notes: notesRef.current.value,
      compoundNumber: cntry == "BH" ? compoundRef.current.value : "",
      streetNumber: cntry == "BH" ? streetRef.current.value : "",
      buildingNumber: cntry == "BH" ? buildingRef.current.value : "",
      apartmentNumber: cntry == "BH" ? apartmentRef.current.value : "",
    };

    if (token) {
      address(data, token)
        .then((response) => {
          localStorage.setItem("addressId", response.data.id);
          localStorage.setItem("addressLat", response.data.latitude);
          localStorage.setItem("addressLong", response.data.longitude);
  
          return near_branches(position.lat, position.lng);
        })
        .then((response) => {
          localStorage.setItem("nearBranches", JSON.stringify(response?.data?.data));
          localStorage.setItem("typeId", 2);
          setModal(true);
          setSubmitLoading(false);
  
          nearBranches()
          if (response.data?.length === 0) {
            setTimeout(() => {
              setModal(false);
              props.setModal(true);
            }, 2000);
          } else {
            setTimeout(() => {
              navigate("/address");
            }, 1000);
          }
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            navigate("/login");
          }
          setSubmitLoading(false);
        });
    }
  }

  // function handleAutoComplete(place) {
  //   handleLocationChange(
  //     place.geometry.location.lat(),
  //     place.geometry.location.lng()
  //   );
  //   setPostion({
  //     lat: place.geometry.location.lat(),
  //     lng: place.geometry.location.lng(),
  //   });
  // }
  /*
     
    function handleChange(e){
      console.log(e.target.value)
      if(e.target.value){
        setEmpty2(false)
      }
      else{
        setEmpty2(true)
      }
    }*/

  //on changing marker location
  //marker lat and lng are sent as arguments in handleLocationChange function
  // function onMarkerDragEnd(evt) {
  //   var newLat = evt.latLng.lat();
  //   var newLng = evt.latLng.lng();
  //   console.log(evt);
  //   handleLocationChange(newLat, newLng);
  //   setPostion({
  //     lat: newLat,
  //     lng: newLng,
  //   });
  // }

  

  function LocationMarker() {
    useMapEvents({
      click(e) {
        setPosition(e.latlng);
        handleLocationChange(e.latlng.lat, e.latlng.lng);
      },
    });

    return position === null ? null : (
      <Marker position={position} icon={customIcon} />
    );
  }

  return (
    <>
      {" "}
      {modal ? <SuccessModal setModal={setModal} style={{zIndex: "100"}}/> : null}
      <div className="row gy-4 addressInput">
        <div className="col-lg-6">
          <p className="addressTitle"> {t("address.new_address")} </p>{" "}
          <form onSubmit={(e) => onSubmit(e)}>
            <div
              style={{
                position: "relative",
              }}
              className="row gy-4"
            >
              <div className="col-12">
                <label> {t("address.address_title")} </label>{" "}
                <input
                  placeholder={t("address.address_title")}
                  ref={nameRef}
                  value={arAddress}
                  id="name"
                  name="name"
                  required={true}
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                />{" "}
                <span>
                  {" "}
                  {formik.errors.name && formik.touched.name
                    ? t(`${formik.errors.name}`)
                    : null}{" "}
                </span>{" "}
              </div>{" "}
              {/*
                            <div className="col-6">
                            <label>{t("address.city")}</label>

                    {/*
                            <Select options={options} value={value} onChange={changeHandler} placeholder={<div className="select-placeholder-text">{t("address.city")}</div>}  onBlur={()=>!value? setEmpty(true):null} className={empty?"invalid":null}/>
                         
                     
                     <select className="addressCity"  defaultValue={""} >
                    <option value="" disabled> {t("branches.city")}</option>

                    {props.cities && props.cities.map((data,index)=>


                    <option key={index}  value={data.id}  >{i18n.language=="ar"?data.arName:data.enName} </option>

                      
                      )
                    } 

                    </select>
                        <span>{empty?t("validation.city_required"):null}</span>
                          </div>

                            <div className="col-6">
                            <label>{t("address.area")}</label>

                         

                            <div className="col-6">
                            <label>{t("address.city")}</label>

                           
                    <Autocomplete
                    disabled={value?false:true}
                                            style={{width: '100%'}}
                                            onPlaceSelected={(place) => handleAutoComplete(place)
                                            
                                            
                                            } 
                                           
                                        options={{
                                            types: ["geocode"],
                                            componentRestrictions: { country: [value.value]},
                                          }}
                                            placeholder= {t("address.area_name")}
                                            id="place" name="place"  required={true} onChange={(e)=>handleChange(e)} onBlur={(e)=>handleChange(e)}  className={empty2?"invalid":null}
                                        />
                                                <span>{empty2?t("validation.place_required"):null}</span>
                            </div>  */}{" "}
              {/* <div className="col-12 autoComplete">
                <label> {t("address.area")} </label>

                <Autocomplete
                  style={{
                    width: "100%",
                  }}
                  onPlaceSelected={(place) => handleAutoComplete(place)}
                  options={{
                    types: ["geocode"],
                    // options.fields:
                    // componentRestrictions: {
                    //   country: ["sa", "bh"],
                    // },
                  }}
                  placeholder={t("address.area_name")}
                  id="place"
                  name="place"
                  defaultValue={arAddress}
                />

                <BsSearch
                  size={27}
                  className={
                    i18n.language == "ar" ? "mapIcn" : " mapIcn mapIcnEn"
                  }
                />
              </div> */}
              {/* {cntry == "BH" ? (
                <>
                  <div className="col-6">
                    <label> {t("address.compound")} </label>{" "}
                    <input
                      placeholder={t("address.compoundNo")}
                      type="number"
                      ref={compoundRef}
                      value={formik.values.compound}
                      id="compound"
                      name="compound"
                      required={true}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={
                        formik.errors.compound && formik.touched.compound
                          ? "invalid"
                          : null
                      }
                    />{" "}
                    <span>
                      {" "}
                      {formik.errors.compound && formik.touched.compound
                        ? t(`${formik.errors.compound}`)
                        : null}{" "}
                    </span>
                  </div>{" "}
                  <div className="col-6">
                    <label> {t("address.street")} </label>{" "}
                    <input
                      placeholder={t("address.streetNo")}
                      type="number"
                      ref={streetRef}
                      value={formik.values.street}
                      id="street"
                      name="street"
                      required={true}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={
                        formik.errors.street && formik.touched.street
                          ? "invalid"
                          : null
                      }
                    />{" "}
                    <span>
                      {" "}
                      {formik.errors.street && formik.touched.street
                        ? t(`${formik.errors.street}`)
                        : null}{" "}
                    </span>
                  </div>{" "}
                  <div className="col-6">
                    <label> {t("address.building")} </label>{" "}
                    <input
                      placeholder={t("address.buildingNo")}
                      type="number"
                      ref={buildingRef}
                      value={formik.values.building}
                      id="building"
                      name="building"
                      required={true}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={
                        formik.errors.building && formik.touched.building
                          ? "invalid"
                          : null
                      }
                    />{" "}
                    <span>
                      {" "}
                      {formik.errors.building && formik.touched.building
                        ? t(`${formik.errors.building}`)
                        : null}{" "}
                    </span>
                  </div>{" "}
                  <div className="col-6">
                    <label> {t("address.apartment")} </label>{" "}
                    <input
                      placeholder={t("address.apartmentNo")}
                      type="number"
                      ref={apartmentRef}
                      value={formik.values.apartment}
                      id="apartment"
                      name="apartment"
                      required={true}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={
                        formik.errors.apartment && formik.touched.apartment
                          ? "invalid"
                          : null
                      }
                    />{" "}
                    <span>
                      {" "}
                      {formik.errors.apartment && formik.touched.apartment
                        ? t(`${formik.errors.apartment}`)
                        : null}{" "}
                    </span>
                  </div>{" "}
                </>
              ) : null}{" "} */}
              <div className="col-12">
                <label> {t("address.notes")} </label>{" "}
                <input ref={notesRef} className="addressNotes" />{" "}
              </div>{" "}
              <div className="col-12">
                {" "}
                {submitLoading ? (
                  <div
                    style={{
                      alignItems: "initial",
                      height: "30px",
                    }}
                    className="loader"
                  >
                    <BeatLoader
                      color={"#ffea13"}
                      loading={submitLoading}
                      size={30}
                    />
                  </div>
                ) : (
                  <>
                    {" "}
                    {outside ? (
                      <button disabled={true} type="submit">
                        {" "}
                        {t("address.outside")}{" "}
                      </button>
                    ) : (
                      <button type="submit">
                        {" "}
                        {t("address.save_address")}{" "}
                      </button>
                    )}{" "}
                  </>
                )}{" "}
              </div>
            </div>
          </form>
        </div>{" "}
        
        <div
          style={{
            marginTop: "2em",
            zIndex: "2"
          }}
          className="col-lg-6 order-first order-lg-last"
        >
        <p className="address-note">* {t("address.map_note")}</p>

        {/* Todo: Change Map from GoogleMap to React Leaflet  */}

        <MapContainer
          center={[position.lat, position.lng]}
          zoom={12}
          style={{ height: '400px', width: '100%' }}
          className="map-addressing"
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <LocationMarker 
            position={position} 
            setPosition={setPosition}
            handleLocationChange={handleLocationChange}
          />
          <CurrentLocationButton
            setPosition={setPosition}
            handleLocationChange={handleLocationChange}
          />
        </MapContainer>

        
          {/* <GoogleMap
            zoom={10}
            center={{
              lat: position.lat,
              lng: position.lng,
            }}
            mapContainerClassName="map"
            options={{
              mapTypeControl: false,
            }}
          >
            <Marker
              icon={homeLocation}
              draggable={true}
              ref={markerRef}
              position={{
                lat: position.lat,
                lng: position.lng,
              }}
              onDragEnd={(e) => onMarkerDragEnd(e)}
            />
          </GoogleMap>{" "} */}
        </div>{" "}
      </div>
    </>
  );
}

export default AddAddress;
