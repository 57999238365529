import "../Styles/Medyaf.css"
import React, {
    useEffect,
    useState
} from 'react';
import {
    IoCloseSharp
} from "react-icons/io5";

import {
    useTranslation
} from 'react-i18next';

function TermsModal(props) {

    const [cartItem, setCartItem] = useState([]);






    return (


        <
        div className = "foodModal" > {
            console.log(cartItem)
        } <
        div onClick = {
            () => props.setModal(false)
        }
        className = "modalOverlay" > < /div> <
        div className = "modal-content"
        style = {
            {
                width: "90% ",
                maxWidth: "400px",
                minWidth: "337px"
            }
        } >

        <
        div style = {
            {
                overflow: "auto",
                height: "100%"
            }
        } > { /* <div style={{height:"85vh",overflow:"auto"}} className='modalBody'>*/ } <
        div className = 'modalBody' >
        <
        div dangerouslySetInnerHTML = {
            {
                __html: props.describe
            }
        }
        />

        <
        /div>


        <
        /div> <
        button className = "close-modal"
        onClick = {
            () => props.setModal(false)
        } >
        <
        IoCloseSharp size = {
            40
        }
        /> <
        /button> <
        /div> <
        /div>


    );
}

export default TermsModal;