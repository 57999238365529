import React, { useEffect, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";

import { useTranslation } from "react-i18next";

function DelAddress(props) {
  const { t, i18n } = useTranslation();

  return (
    <div className="foodModal">
      <div onClick={() => props.setModal(false)} className="modalOverlay">
        {" "}
      </div>{" "}
      <div
        className="modal-content"
        style={{
          height: "auto",
          width: "50vw ",
          maxWidth: "400px",
          minWidth: "337px",
        }}
      >
        <div>
          <div
            style={{
              height: "20vh",
              flexDirection: "column",
              textAlign: "center",
              justifyContent: "space-around",
            }}
            className="modalBody"
          >
            <p className="cancelOrder"> {t("profile.delete_address")} </p>{" "}
            <div className="modalBtns">
              <button
                className="confirm"
                onClick={() => {
                  props.handleDelete();
                  props.setModal(false);
                }}
              >
                {" "}
                {t("Login.confirm")}{" "}
              </button>{" "}
              <button onClick={() => props.setModal(false)} className="cancel">
                {" "}
                {t("profile.cancel")}{" "}
              </button>
            </div>
          </div>
        </div>{" "}
        <button className="close-modal" onClick={() => props.setModal(false)}>
          <IoCloseSharp size={40} />{" "}
        </button>{" "}
      </div>{" "}
    </div>
  );
}

export default DelAddress;
