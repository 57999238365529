import React, { useState } from "react";
import "../Styles/MainMenu.css";
import { HiShoppingCart } from "react-icons/hi";
import { useParams, generatePath } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BackgroundImage } from "react-image-and-background-image-fade";
import { BiSolidHot } from "react-icons/bi";
import logo from "../Images/comm.png";
import { IoWarning } from "react-icons/io5";

function MenuItem(props) {
  let history = useNavigate();
  const { t, i18n } = useTranslation();
  const id = props.id;

  //on choosing menu item user is redirected to /meals (SingleProduct component in /Pages) with menu id as params
  const handleProceed = (e) => {
    history(
      generatePath("/meals/:id", {
        id,
      })
    );
  };

  return (
    <div className="col-lg-4 food-card col-sm-6">
      <div className="p-3 mmMenuItems">
        <p className="mmName"> {props.name} </p>{" "}
        {/*  <div  className='menuImage' style={{ backgroundImage: `url(${props.image})` }}>

                    </div> */}
        <div style={{ position: `relative` }}>
          <BackgroundImage src={props.image || logo} width="100%" className="menuImage" />
          {props.calories > 0 && 
            <p className="mmCalories"> 
              <BiSolidHot style={{ fontSize: `medium` }} /> {" "}
              {props.calories} {t('party.calories')}
            </p>
          }
        </div>
        <p className="mmDescription"> {props.describe} </p>{" "}
{/* 
        {props.indication && 
          <p className="sp-p-indication"> 
            <IoWarning style={{ fontSize: `medium`, color: "#955601" }} /> {" "}
            <span style={{color: "#955601"}}>{t('party.indication')}:</span> {props.indication}
          </p>
        } 
*/}

        <p className="mmPrice">
          {" "}
          {props.price} {props.currency}{" "}
        </p>{" "}
        <button disabled={props.active !== 1} onClick={handleProceed}>
          {" "}
          <HiShoppingCart /> {t("party.addToBasket")}{" "}
        </button>{" "}
      </div>{" "}
    </div>
  );
}
export default MenuItem;
