import "../Styles/UserProfile.css";

import { useTranslation } from "react-i18next";

import { getUserOrders, orders } from "../api-nano";
import { useState, useEffect } from "react";
import { generatePath } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import UserNav from "../Components/UserNav";
import Logout from "../Components/Logout";
import { BeatLoader } from "react-spinners";
import Footer from "../Components/Footer";

function MyOrders(props) {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState([]);

  const [modal, setModal] = useState(false);
  let history = useNavigate();

  //on clicking on an order's details button user is redirected to /order-track (OrderTracking component in /Pages) with order id as params
  // const handleProceed = (id) => {
  //   history(
  //     generatePath("/order-track/:id", {
  //       id,
  //     })
  //   );
  // };

  const handleProceed = (id) => {
    history(`/order-track/${id}`);
  };

  //fetch user orders
  useEffect(() => {
    const token = window.localStorage.getItem("token");
    console.log(token)
    getUserOrders(token).then((response) => {
      setOrder(response.data.data);
      console.log("orders is", response.data);
      setLoading(false);
    });
  }, []);

  return (
    <div>
      {" "}
      {modal ? (
        <Logout setModal={setModal} setCartItem={props.setCartItem} />
      ) : null}{" "}
      <div className="userProfile">
        <UserNav setModal={setModal} nav={0} />{" "}
        {loading ? (
          <div className="loader profileLoader">
            <BeatLoader color={"#ffea13"} loading={loading} size={30} />
          </div>
        ) : (
          <div className="container-profile-header aboutusBody profileBody container-fluid fluid">
            <p className="upTable-title"> {t("profile.orders")} </p>{" "}
            <div className="upTable table-responsive">
              <table className="table align-middle table-striped table-hover table-borderless ">
                <thead>
                  <tr>
                    <th scope="col"> {t("profile.orderId")} </th>{" "}
                    <th scope="col"> {t("profile.date")} </th>{" "}
                    <th scope="col"> {t("profile.type")} </th>{" "}
                    <th scope="col"> {t("profile.order-total")} </th>{" "}
                    <th scope="col"> {t("profile.branch")} </th>
                    <th scope="col"> </th>{" "}
                  </tr>{" "}
                </thead>{" "}
                <tbody>
                  {" "}
                  {order &&
                    order.map((data, index) => (
                      <tr>
                        <td> {data.id} </td>
                        <td> {data.created_at} </td>{" "}
                        <td> {data?.order_types.name} </td>{" "}
                        <td>
                          {" "}
                          {data.order_total}{" "}
                          {i18n.language == "ar"
                            ? data.arCurrency
                            : data.enCurrency}{" "}
                        </td>{" "}
                        <td> {data?.from_company} </td>
                        <th scope="row">
                          <button onClick={() => handleProceed(data.id)}>
                            {" "}
                            {t("profile.show-details")}{" "}
                          </button>{" "}
                        </th>{" "}
                      </tr>
                    ))}
                    {" "}

                  {/* {order &&
                    order.map((data, index) => (
                      <tr>
                        <td> {data.id} </td> <td> {data.created_at} </td>{" "}
                        <td> {data.name} </td>{" "}
                        <td>
                          {" "}
                          {data.totalPrice}{" "}
                          {i18n.language == "ar"
                            ? data.arCurrency
                            : data.enCurrency}{" "}
                        </td>{" "}
                        <td>
                          {" "}
                          {i18n.language == "ar"
                            ? data.branchArName
                            : data.branchEnName}{" "}
                        </td>
                        <th scope="row">
                          <button onClick={() => handleProceed(data.id)}>
                            {" "}
                            {t("profile.show-details")}{" "}
                          </button>{" "}
                        </th>{" "}
                      </tr>
                    ))}{" "}
                  {order.waitingPayment &&
                    order.waitingPayment.map((data, index) => (
                      <tr>
                        <td> {data.id} </td> <td> {data.createdAt} </td>{" "}
                        <td>
                          {" "}
                          {data.typeId == 1
                            ? t("home.option-branch")
                            : data.typeId == 2
                            ? t("home.option-home")
                            : t("home.option-car")}{" "}
                        </td>{" "}
                        <td>
                          {" "}
                          {data.totalPrice}{" "}
                          {i18n.language == "ar"
                            ? data.arCurrency
                            : data.enCurrency}{" "}
                        </td>{" "}
                        <td>
                          {" "}
                          {i18n.language == "ar"
                            ? data.branchArName
                            : data.branchEnName}{" "}
                        </td>
                        <th scope="row">
                          <button onClick={() => handleProceed(data.id)}>
                            {" "}
                            {t("profile.show-details")}{" "}
                          </button>{" "}
                        </th>{" "}
                      </tr>
                    ))} */}
                </tbody>{" "}
              </table>
            </div>
          </div>
        )}
      </div>{" "}
      {/* <Footer /> */}
    </div>
  );
}

export default MyOrders;
