import React, { useEffect, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CustomerInfo } from "../api-nano";
// import { getAnalytics, logEvent } from "firebase/analytics";
import { BeatLoader } from "react-spinners";
// import { app } from "../Firebase/firebaseConfig";

function Logout(props) {
  const { t, i18n } = useTranslation();
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(true);
  const history = useNavigate();
  // const analytics = getAnalytics(app);

  //logout removes certain items from local storage
  //user is then directed to home screen
  function handleLogout() {
    //used to change cart icon's current value to 0
    props.setCartItem(0);
    localStorage.removeItem("token");
    localStorage.removeItem("userPhone");
    localStorage.removeItem("userName");
    localStorage.removeItem("basket");
    //was used to store user's current step in cart process
    localStorage.removeItem("process");

    history("/");
  }

  return (
    <div className="foodModal">
      <div onClick={() => props.setModal(false)} className="modalOverlay">
        {" "}
      </div>{" "}
      <div
        className="modal-content logoutModal"
        style={{
          height: "auto",
          width: "50vw ",
          maxWidth: "400px",
          minWidth: "337px",
        }}
      >
        <div>
          <div
            style={{
              height: "20vh",
              flexDirection: "column",
              textAlign: "center",
              justifyContent: "space-around",
            }}
            className="modalBody"
          >
            <p className="cancelOrder"> {t("profile.logout-modal")} </p>{" "}
            <div className="modalBtns">
              <button className="confirm" onClick={handleLogout}>
                {" "}
                {t("profile.logout")}{" "}
              </button>{" "}
              <button onClick={() => props.setModal(false)} className="cancel">
                {" "}
                {t("profile.cancel")}{" "}
              </button>
            </div>
          </div>
        </div>{" "}
        <button className="close-modal" onClick={() => props.setModal(false)}>
          <IoCloseSharp size={40} />{" "}
        </button>{" "}
      </div>{" "}
    </div>
  );
}

export default Logout;
