// import "../Styles/Footer.css";
// import { Link } from "react-router-dom";
// import p1 from "../Images/Footer/payment1.svg";
// import p2 from "../Images/Footer/payment2.svg";
// import p3 from "../Images/Footer/payment3.svg";
// import p4 from "../Images/Footer/payment4.svg";
// import vat from "../Images/Footer/vat.svg";
// import logoAr from "../Images/comm1.png";
// import logoEn from "../Images/comm.png";
// import maroof from "../Images/Maroof.svg";
// import { useTranslation } from "react-i18next";

// function Footer() {
//   const { t, i18n } = useTranslation();
//   var dt = new Date();
//   const year = dt.getFullYear();
//   return (
//     <div id="footer" className="footer container-fluid">
//       <div className="container">
//         <div className="row gy-4">
//           <div className="col-sm-4 footerVat">
//             <img
//               className="footerLogo"
//               src={i18n.language == "ar" ? logoAr : logoEn}
//             />
//           </div>{" "}
//           <div className="col-sm-4 paymentMethods">
//             <img src={p3} /> <img src={p4} />{" "}
//           </div>{" "}
//           <div
//             className={
//               i18n.language == "ar"
//                 ? "col-sm-4 footerEndAr"
//                 : "col-sm-4 footerEnd"
//             }
//           >

//           </div>{" "}
//         </div>{" "}
//         <div className="copyRights">
//           <p>
//             {" "}
//             {t("footer.copy_write_parg")}© {year}{" "}
//           </p>{" "}
//           {/* Todo: add developed company to footer section */}
//           <p>
//             {" "}
//             {t("footer.developed_by")}
//             {": "}
//             <a href="https://www.nano2soft.com/ar" target="_blank" className="developedCompany">{t("footer.developed_company")}</a>
//           </p>{" "}
//         </div>{" "}
//       </div>
//     </div>
//   );
// }

// export default Footer;




import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaFacebookSquare , FaInstagram, FaTwitterSquare } from "react-icons/fa";
import logoAr from "../Images/comm.png";
import logoEn from "../Images/comm.png";
import "../Styles/Footer.css";
import p3 from "../Images/Footer/payment3.svg";
import p4 from "../Images/Footer/payment4.svg";
import qr1 from "../Images/Footer/qr1.png";  // Add your QR code images
import qr2 from "../Images/Footer/qr2.png";
import qr3 from "../Images/Footer/qr3.png";

function Footer() {
  const { t, i18n } = useTranslation();
  const year = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content container">
        <div className="row">
          <div className="col-md-4">
            <img 
              src={i18n.language == "ar" ? logoAr : logoEn}
              alt="Rydan Logo" 
              className="footer-logo"
            />
            <p className="footer-description">{t("footer.mission")}</p>
          </div>
          <div className="col-md-4">
            <h3>{t("footer.contact")}</h3>
            <p>{t("footer.address")}</p>
            <p>{t("footer.phone")} : 966531833995+</p>
          </div>
          <div className="col-md-4">
            <h3>{t("footer.follow_us")}</h3>
            <div className="social-icons">
              <a href="#" target="_blank" rel="noopener noreferrer"><FaFacebookSquare /></a>
              <a href="#" target="_blank" rel="noopener noreferrer"><FaTwitterSquare /></a>
              <a href="#" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
            </div>

            <div className="col-sm-4 payment-methods ">
              <img src={p3} /> <img src={p4} />{" "}
            </div>{" "}

            <div className="qr-codes-container">
              <div className="qr-code-item">
                <a href="https://salamah.998.gov.sa/#/public/certificate-details?code=MVpp1DDpP-jMUra_AULk5w" target="_blank" rel="noopener noreferrer">
                  <div className="qr-code-wrapper">
                    <img src={qr1} alt="QR Code 1" className="qr-code-image" />
                    <div className="qr-overlay">
                      <span>{t('footer.scanQRcode')}</span>
                    </div>
                  </div>
                  <h4 className="qr-title">{t('footer.qr1')}</h4>
                </a>
              </div>

              <div className="qr-code-item">
                <a href="https://qr.mc.gov.sa/info/review?lang=ar&q=19R%209q%20WY%20pBGNCkwSQisw%3D%3D" target="_blank" rel="noopener noreferrer">
                  <div className="qr-code-wrapper">
                    <img src={qr3} alt="QR Code 3" className="qr-code-image" />
                    <div className="qr-overlay">
                      <span>{t('footer.scanQRcode')}</span>
                    </div>
                  </div>
                  <h4 className="qr-title">{t('footer.qr3')}</h4>
                </a>
              </div>
              
              <div className="qr-code-item">
                <a href="https://ebalady.momra.gov.sa/commercial/faces/LicencesDetail.jsf;jsessionid=oeuoY_eybPZqPMy3F_fMrKaYuCQ1KlfJ0auot9mHrvsLxCOEpC7I!1840767660?data=bGljZW5zZU51bWJlcj0zOTA5MTEyODAz" target="_blank" rel="noopener noreferrer">
                  <div className="qr-code-wrapper">
                    <img src={qr2} alt="QR Code 2" className="qr-code-image" />
                    <div className="qr-overlay">
                      <span>{t('footer.scanQRcode')}</span>
                    </div>
                  </div>
                  <h4 className="qr-title">{t('footer.qr2')}</h4>
                </a>
              </div>
              

            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; {year} {t("footer.copy_write_parg")}</p>
          <p>{t("footer.developed_by")}: <a href="https://www.nano2soft.com/ar" target="_blank" rel="noopener noreferrer" ><span>{t("footer.developed_company")}</span></a></p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;