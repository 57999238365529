import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import PhoneInput from 'react-phone-number-input';
import { BeatLoader } from "react-spinners";
import { sendActivation, handleValidate, changePassword } from "../api-nano";

import key from "../Images/Login/KeyIcon.svg";
import mobile from "../Images/Login/mobile.svg";
import laptop from "../Images/Login/Laptop.svg";
import changePassIcon from "../Images/Login/changePass.svg";

function ForgetPassword() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  
  const [stage, setStage] = useState("phone");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  // OTP Validation Schema
  const otpSchema = Yup.object().shape({
    otp: Yup.string()
      .required(t("validation.otp_required"))
      .length(6, t("validation.otp_length"))
  });

  // Password Change Validation Schema
  const passwordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required(t("validation.password_required"))
      .min(8, t("validation.password_min_length")),
    confirmPassword: Yup.string()
      .required(t("validation.confirm_password_required"))
      .oneOf([Yup.ref('newPassword')], t("validation.passwords_must_match"))
  });

  // Phone Number Form
  const phoneFormik = useFormik({
    initialValues: { phone: "" },
    onSubmit: async (values) => {
      setLoading(true);
      setError("");
      try {
        const data = await sendActivation({
          provider: "frontend",
          mobile: values.phone,
          field_name: "check_login",
          channel: "otp_whatsapp",
          channel_type: "whatsapp"
        });

        console.log("Phone Number Form => ", data)
        setPhoneNumber(values.phone);
        setStage("otp");
      } catch (error) {
        setError(t("errors.send_otp_failed"));
      } finally {
        setLoading(false);
      }
    }
  });

  // OTP Form
  const otpFormik = useFormik({
    initialValues: { otp: "" },
    validationSchema: otpSchema,
    onSubmit: async (values) => {
      setLoading(true);
      setError("");
      try {
        const response = await handleValidate({
          provider: "frontend",
          loginAttribute: "mobile",
          mobile: phoneNumber,
          field_name: "check_login",
          token: values.otp,
          is_profile: true,
          // nano_referral: Cookies.get('referral_alias')   // referral_alias from cookies 
        });

        if (response.data.message === "success") {
          localStorage.setItem("token", response.data.token);
          setStage("changePassword");
        } else {
          setError(t("errors.invalid_otp"));
        }
      } catch (error) {
        setError(t("errors.validate_otp_failed"));
      } finally {
        setLoading(false);
      }
    }
  });

  // Change Password Form
  const changePasswordFormik = useFormik({
    initialValues: { 
      newPassword: "", 
      confirmPassword: "" 
    },
    validationSchema: passwordSchema,
    onSubmit: async (values) => {
      setLoading(true);
      setError("");
      try {
        const token = localStorage.getItem("token");
        await changePassword({
          provider: "frontend",
          password: values.newPassword,
          password_confirmation: values.confirmPassword
        }, token);
        
        localStorage.removeItem("token");
        navigate("/login");
      } catch (error) {
        setError(t("errors.change_password_failed"));
      } finally {
        setLoading(false);
      }
    }
  });

  // Render Phone Input Stage
  const renderPhoneStage = () => (
    <div className="col-md-6 passAlign">
      <img src={key} alt="Key Icon" /> 
      <p className="forgot">{t("Login.forgetYour")}</p>
      <p className="dontWorry">{t("Login.dontWorry")}</p>
      
      <form className="passForm" onSubmit={phoneFormik.handleSubmit}>
        <label>{t("Login.enterPhone")}</label>
        <PhoneInput
          className="phoneNumber"
          id="phone"
          name="phone"
          defaultCountry="SA"
          international
          placeholder={t("contact.byPhone")}
          value={phoneFormik.values.phone}
          onChange={(value) => phoneFormik.setFieldValue("phone", value)}
        />
        
        {error && <div className="error-message">{error}</div>}
        
        <div className="formBTn">
          <button type="submit" disabled={loading}>
            {loading ? <BeatLoader color="#ffea13" size={10} /> : t("Login.confirm")}
          </button>
        </div>
      </form>
    </div>
  );

  // Render OTP Stage
  const renderOTPStage = () => (
    <div className="col-md-6 passAlign">
      <img src={mobile} alt="Mobile Icon" />
      <p className="forgot">{t("Login.checkPhone")}</p>
      <p className="otpPhone">{phoneNumber}</p>
      
      <form className="passForm" onSubmit={otpFormik.handleSubmit}>
        <label>{t("Login.EnterVerify")}</label>
        <input
          type="text"
          name="otp"
          maxLength="6"
          value={otpFormik.values.otp}
          onChange={otpFormik.handleChange}
          placeholder={t("Login.enterOTP")}
          className="forget-input"
        />
        
        {error && <div className="error-message">{error}</div>}
        
        <div className="formBTn">
          <button type="submit" disabled={loading}>
            {loading ? <BeatLoader color="#ffea13" size={10} /> : t("Login.confirm")}
          </button>
        </div>
      </form>
    </div>
  );

  // Render Change Password Stage
  const renderChangePasswordStage = () => (
    <div className="col-md-6 passAlign">
      <img src={changePassIcon} alt="Change Password Icon" />
      <p className="forgot">{t("Login.changePassword")}</p>
      
      <form className="passForm" onSubmit={changePasswordFormik.handleSubmit}>
        <label>{t("Login.newPassword")}</label>
        <input
          type="password"
          name="newPassword"
          value={changePasswordFormik.values.newPassword}
          onChange={changePasswordFormik.handleChange}
          placeholder={t("Login.enterNewPassword")}
        />
        
        <label>{t("Login.confirmPassword")}</label>
        <input
          type="password"
          name="confirmPassword"
          value={changePasswordFormik.values.confirmPassword}
          onChange={changePasswordFormik.handleChange}
          placeholder={t("Login.confirmNewPassword")}
        />
        
        {error && <div className="error-message">{error}</div>}
        
        <div className="formBTn">
          <button type="submit" disabled={loading}>
            {loading ? <BeatLoader color="#ffea13" size={10} /> : t("Login.changePassword")}
          </button>
        </div>
      </form>
    </div>
  );

  return (
    <div className="container-all loginContainer container-fluid fluid">
      <div className="container-fluid login" style={{ padding: "6em 2em" }}>
        {loading && (
          <div className="loader" style={{ height: "50vh" }}>
            <BeatLoader color="#ffea13" loading={loading} size={30} />
          </div>
        )}
        
        <div className="row">
          {stage === "phone" && renderPhoneStage()}
          {stage === "otp" && renderOTPStage()}
          {stage === "changePassword" && renderChangePasswordStage()}
          
          <div className="col-md-6 passAlignImg">
            {stage === "phone" && <img src={laptop} alt="Laptop" />}
            {stage === "otp" && <img src={changePassIcon} alt="Change Password" />}
            {stage === "changePassword" && <img src={key} alt="Key" />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;