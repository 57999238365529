import "../Styles/CartProcess.css";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { checkout_step } from "../api-nano";

function CartProcess2(props) {
  const { t, i18n } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(
    localStorage.getItem("timeNow")
      ? JSON.parse(localStorage.getItem("timeNow"))
      : true
  );
  const [selectedDelivery, setSelectedDelivery] = useState(12);
  const [time, setTime] = useState();
  const [datePicker, setDatePicker] = useState(
    localStorage.getItem("timeNow")
      ? !JSON.parse(localStorage.getItem("timeNow"))
      : false
  );
  const [startDate, setStartDate] = useState(
    new Date().setHours(new Date().getHours() + 2)
  );
  const [minTime, setMinTime] = useState(
    new Date().setHours(new Date().getHours() + 2)
  );
  const [date, setDate] = useState(
    new Date().getFullYear() +
      "-" +
      (new Date().getMonth() + 1) +
      "-" +
      new Date().getDate()
  );
  let current = new Date();
  let d = current.getDate();
  let m = current.getMonth() + 1;
  let y = current.getFullYear();
  const messgRef = useRef();
  const [chooseDelivery, setChooseDelivery] = useState();
  const [showDelivery, setshowDelivery] = useState(false);
  const [currency, setCurrency] = useState({
    ar: "",
    en: "",
  });

  useEffect(() => {
    if (localStorage.getItem("typeId") == 2) {
      setshowDelivery(true); //if order type is address delievery options are displayed
      setChooseDelivery(JSON.parse(localStorage.getItem("delivery"))); //delivery data (was stored in CartProcess1 from Get_Basket api call response)
      if (!localStorage.getItem("deliveryType")) {
        //store default chosen delivery type for use in analytics in CartProcess3

        localStorage.setItem(
          "deliveryType",
          JSON.parse(localStorage.getItem("delivery"))?.ordinaryDeliveryOption
            ? "ordinary"
            : "fast"
        );
      }
    }

    if (
      localStorage.getItem("orderDate") &&
      localStorage.getItem("timeNow") == "false"
    ) {
      setStartDate(new Date(localStorage.getItem("orderDate")));
    }
    setCurrency({
      //was stored in CartProcess1 (from Get_Basket api call response)
      ar: localStorage.getItem("currencyAr"),
      en: localStorage.getItem("currencyEn"),
    });

    //for setting default selected date
    if (d < 10) {
      d = "0" + d;
    }
    if (m < 10) {
      m = "0" + m;
    }
    let selectedDate = y + "-" + m + "-" + d;
    setDate(selectedDate);

    //for setting default selected time
    let hh = current.getHours() + 2;
    let mm = current.getMinutes();

    if (hh < 10) {
      hh = "0" + hh;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    let selectedTime = hh + ":" + mm;
    setTime(selectedTime);
  }, []);

  //sets selected date
  //then sets selected time and date sperately
  const handleChangeTime = (date) => {
    if (date.toLocaleDateString() === new Date().toLocaleDateString()) {
      setStartDate(date);
      setMinTime(new Date().setHours(new Date().getHours() + 2));
    } else {
      setStartDate(date);
      setMinTime(new Date(new Date().setHours(0, 0)));
    }

    let d = date.getDate();
    let m = date.getMonth() + 1;
    let y = date.getFullYear();

    if (d < 10) {
      d = "0" + d;
    }
    if (m < 10) {
      m = "0" + m;
    }
    let selectedDate = y + "-" + m + "-" + d;

    let hh = date.getHours();
    let mm = date.getMinutes();

    if (hh < 10) {
      hh = "0" + hh;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    let selectedTime = hh + ":" + mm;

    setDate(selectedDate);
    setTime(selectedTime);
  };

  //sets selected delivery time (now = true/now = false)
  //if now = true =>date picker is not shown
  function handleOptionChange(e) {
    const val = e.target.value;
    if (val == "true") {
      setSelectedOption(true);
      setDatePicker(false);
    } else {
      setSelectedOption(false);
      setDatePicker(true);
    }
  }

  //sets delivery type to ordinary and stores it for use in CartProcess3
  function handleDeliveryOrd(e) {
    const val = e.target.value;

    setSelectedDelivery(val);
    localStorage.setItem("deliveryType", "ordinary");
  }
  //sets delivery type to fast and stores it for use in CartProcess3
  function handleDeliveryFast(e) {
    const val = e.target.value;

    setSelectedDelivery(val);
    localStorage.setItem("deliveryType", "fast");
  }

  //takes user to next step in cart process and stores it
  //stores user order data to be used in third step

  async function handleContinue() {
    localStorage.setItem("timeNow", selectedOption);
    //localStorage.setItem("cartMessage",messgRef.current.value);
    localStorage.setItem("orderDate", startDate);
    var data = "";
    if (selectedOption == true) {
      //when now option is true , date and time are set to empty
      data = {
        now: selectedOption,
        delivery: selectedDelivery,
        date: "",
        time: "",
        message: "",
      };
    } else {
      data = {
        now: selectedOption,
        delivery: selectedDelivery,
        date: date,
        time: time,
        message: "",
      };
    }
    const token = window.localStorage.getItem("token");
    const dataAd = JSON.parse(localStorage.getItem("addressData"));
    let branchId = localStorage.getItem("branchId");
    let mData = {
      ...dataAd,
      billing_address_id: dataAd?.id,
      shipping_address_id: dataAd?.id,
      shipping_address: "tes tdfs sf",
      shipping_address_1: "The shipping address 1 field is ",
      shipping_country: "Ye",
      departments_id: branchId,
      // customer_id: "",
      // "2from_address_1": "جولة المجد",
      // from_country: "Ye",
      // from_state: 428,
      // from_lat: 21.534847,
      // from_lang: 57.304687,
      // shipping_firstName: "tes tdfs sf",
      // shipping_lastname: "The shipping lastname field is required.",
      // shipping_lat: 21.534847,
      // shipping_lng: 57.304687,
      // shipping_address_1: "The shipping address 1 field is ",
      // shipping_state: false,
      // shipping_email: "eee@eee.com",
      // shipping_state: 428,
      // shipping_country: "Ye",
      // shipping_price: "500.00",
      // shipping_distance: 5109.1666115797325,
      // payment_method_id: 1,
      // order_type22: "taxi",
      // vehicle_type_id: "3",
      // vehicle_type_ref_type: "LAND_BUSES_SMALL",
      // tip_amount: "100",
      // expected_shipping_total: 0,
      // coupon_name: "tip",
      // coupon_data: null,
      // order_type: "delivery_shop",
      // shipping_address_1: "",
      // step2: "pay",
      step: "details_shiping_coupon",
      // shipping_address: {

      // },
    };
    await checkout_step(token, mData, "details_shiping_coupon")
      .then((response) => {
        console.log("the details response is", response);
        localStorage.setItem("order_details", JSON.stringify(data));
        props.setNav(2);
        localStorage.setItem("process", 2);
      })
      .catch((err) => {
        console.log("the eror", err);
      });
    /*
          localStorage.setItem("time",selectedOption);
          localStorage.setItem("delivery",selectedDelivery);

          if (selectedOption==false){

          localStorage.setItem("date",startDate);
          }
         
          props.setNav(2)*/
  }

  return (
    <div className="cartProcess">
      <div className="container-fluid fluid ">
        <div className="cpMiddle-title2">
          <p> {t("process.orderWay")} </p>{" "}
        </div>{" "}
        <div className="cpMiddle-options">
          {" "}
          {/*
            <label className="cpForm" htmlFor="radio1"> {t("process.add_note")}</label>
            <input type="text" className="message" defaultValue={localStorage.getItem("cartMessage")?localStorage.getItem("cartMessage"):""} ref={messgRef} placeholder={t("process.enter_note")}/>
            */}{" "}
          <div
            className="cpRadio"
            style={{
              marginTop: "0",
            }}
          >
            <p className="cpForm"> {t("process.order_time")} </p>{" "}
            <div className="row">
              <div className="col-md-4">
                <input
                  value={true}
                  type="radio"
                  name="time"
                  defaultChecked={selectedOption == true ? true : false}
                  onChange={(e) => handleOptionChange(e)}
                />{" "}
                <label> {t("process.now")} </label>{" "}
              </div>{" "}
              <div className="col">
                <input
                  value={false}
                  type="radio"
                  name="time"
                  defaultChecked={selectedOption == false ? true : false}
                  onChange={(e) => handleOptionChange(e)}
                  // Todo: make this input radio disabled, because we don't use it yet
                  disabled
                />{" "}
                <label> {t("process.another")} </label>
              </div>{" "}
            </div>{" "}
          </div>{" "}
          {/* {datePicker == true ? (
            <div
              style={{
                marginTop: "1em",
              }}
              className={
                i18n.language == "ar"
                  ? "cpRadio datePicker datePickerAr"
                  : "cpRadio datePicker"
              }
            >
              <DatePicker
                required
                selected={startDate}
                showTimeSelect
                dateFormat=" h:mm ,yyyy/MM/dd"
                minTime={minTime}
                maxTime={new Date(new Date().setHours(23, 59))}
                minDate={new Date()}
                timeFormat="HH:mm"
                onChange={handleChangeTime}
              />
            </div>
          ) : null} */}
          {/* {showDelivery ? (
            <div className="cpRadio">
              <p className="cpForm"> {t("process.delivery_options")} </p>{" "}
              <div className="row">
                {chooseDelivery?.ordinaryDeliveryOption ? (
                  <div className="col-lg-6">
                    <input
                      value={chooseDelivery?.ordinaryDeliveryPrice}
                      type="radio"
                      name="options"
                      defaultChecked={
                        localStorage.getItem("deliveryType")
                          ? localStorage.getItem("deliveryType") == "ordinary"
                            ? true
                            : false
                          : true
                      }
                      onChange={(e) => handleDeliveryOrd(e)}
                    />{" "}
                    <label>
                      {" "}
                      {t("process.normal")} &#160;
                      <span>
                        ({chooseDelivery?.ordinaryDeliveryPrice}{" "}
                        {i18n.language == "ar" ? currency.ar : currency.en}){" "}
                      </span>
                    </label>
                  </div>
                ) : null}{" "}
                {chooseDelivery?.fastDeliveryOption ? (
                  <div className="col-lg-6">
                    <input
                      value={chooseDelivery?.fastDeliveryPrice}
                      type="radio"
                      name="options"
                      defaultChecked={
                        localStorage.getItem("deliveryType")
                          ? localStorage.getItem("deliveryType") == "fast"
                            ? true
                            : false
                          : !chooseDelivery?.ordinaryDeliveryOption
                          ? true
                          : false
                      }
                      onChange={(e) => handleDeliveryFast(e)}
                    />{" "}
                    <label>
                      {" "}
                      {t("process.fast")} &#160;
                      <span>
                        ({chooseDelivery?.fastDeliveryPrice}{" "}
                        {i18n.language == "ar" ? currency.ar : currency.en}){" "}
                      </span>
                    </label>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null} */}
        </div>{" "}
        <div className="cartNext cartBack">
          {/* Todo: Add back button on Sales basket on cart */}
          <button
            onClick={() => {
              props.setNav(0);
              localStorage.setItem(
                "process",
                0
              ); 
            }}
          >
            {" "}
            {t("process.back")}{" "}
          </button>

          <button onClick={handleContinue}> {t("process.next")} </button>
        </div>
      </div>
    </div>
  );
}

export default CartProcess2;
