import React, { useEffect, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function ChangeType(props) {
  const { t, i18n } = useTranslation();
  const [cartItem, setCartItem] = useState([]);
  let history = useNavigate();

  return (
    <div className="foodModal">
      <div onClick={() => props.setModal(false)} className="modalOverlay">
        {" "}
      </div>{" "}
      <div
        className="modal-content"
        style={{
          height: "auto",
          width: "50vw ",
          maxWidth: "400px",
          minWidth: "337px",
          paddingBottom: "1.5em",
        }}
      >
        <div>
          <div
            style={{
              flexDirection: "column",
              textAlign: "center",
              justifyContent: "space-around",
            }}
            className="modalBody"
          >
            <p
              className="cancelOrder"
              style={{
                margin: "1em 0",
              }}
            >
              {t(`home.${props.text}`)}{" "}
            </p>{" "}
            <div className="modalBtns">
              <button
                className="confirm"
                onClick={() => {
                  props.handleClear();
                  props.setModal(false);
                  localStorage.setItem(
                    "typeId",
                    props.navigate == "/branch" ? 1 : 2
                  );
                  history(props.navigate);
                }}
              >
                {" "}
                {t("home.continue")}{" "}
              </button>{" "}
              <button onClick={() => props.setModal(false)} className="cancel">
                {" "}
                {t("profile.cancel")}{" "}
              </button>
            </div>
          </div>
        </div>{" "}
        <button className="close-modal" onClick={() => props.setModal(false)}>
          <IoCloseSharp size={40} />{" "}
        </button>{" "}
      </div>{" "}
    </div>
  );
}

export default ChangeType;
