import "../Styles/CompanySales.css";

import { useTranslation } from "react-i18next";
import { IoCall } from "react-icons/io5";

function CompanyServices(props) {
  const { t, i18n } = useTranslation();

  return (
    <div className="row gy-4">
      {" "}
      {props.salesServices &&
        props.salesServices.map((data, index) => (
          <div className="col-12">
            <div
              style={{
                position: "relative",
              }}
              className="row"
            >
              <div className="col-lg-3 col-5 companyServices">
                <div
                  className="bckg "
                  style={{
                    backgroundImage: `url(${data.featuredImg})`,
                  }}
                >
                  {" "}
                </div>{" "}
              </div>{" "}
              <div className="col-lg-9 col-7">
                <p className="serviceTitle">
                  {" "}
                  {i18n.language == "ar" ? data.arName : data.enName}{" "}
                </p>{" "}
                <p className="serviceDet">
                  {" "}
                  {i18n.language == "ar"
                    ? data.arDescription
                    : data.enDescription}{" "}
                </p>{" "}
              </div>{" "}
              <a href="tel:0502299281">
                <button
                  className={
                    i18n.language == "en"
                      ? "callService callServiceEn"
                      : "callService"
                  }
                >
                  {" "}
                  <IoCall /> &nbsp; {t("companySales.callRep")}{" "}
                </button>{" "}
              </a>
            </div>
          </div>
        ))}
    </div>
  );
}

export default CompanyServices;
