import "../Styles/UserProfile.css";

import { useState } from "react";
import UserNav from "../Components/UserNav";
import Logout from "../Components/Logout";

import MyPoints from "./MyPoints";

function MyPointsProfile(props) {
  const [modal, setModal] = useState(false);

  return (
    <>
      <div>
        {" "}
        {modal ? (
          <Logout setModal={setModal} setCartItem={props.setCartItem} />
        ) : null}{" "}
        <div className="userProfile">
          <UserNav setModal={setModal} nav={2} />{" "}
            <>
              {" "}
                <div className="aboutusBody profileBody container-fluid fluid">
                <MyPoints />
                </div>
                {" "}
            </>
        </div>{" "}
      </div>{" "}
    </>
  );
}

export default MyPointsProfile;
