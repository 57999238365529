import "../Styles/UserProfile.css";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useFormik } from "formik";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import { BeatLoader } from "react-spinners";
import { changePassword } from "../api-nano";
import { useNavigate } from "react-router-dom";

function ProfileChangePass(props) {
  const [hidden3, setHidden3] = useState(false);

  const [loading, setLoading] = useState(false);

  const [hidden, setHidden] = useState(false);
  const [hidden2, setHidden2] = useState(false);
  const [msgEn, setMsgEn] = useState();
  const [msgAr, setMsgAr] = useState();
  const [errorMsgModel, setErrorMsgModel] = useState(false);
  const history = useNavigate();
  const { t, i18n } = useTranslation();

  const Schema = yup.object().shape({
    password: yup
      .string()
      .min(6, t("validation.at_least"))
      .required(t("validation.password_required")),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], t("validation.password_must_match"))
      .required(t("validation.confirm_required")),
  });


  //for showing and hiding password when clicking on eye icon
  function show() {
    setHidden(!hidden);
    var x = document.getElementById("pass1");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  //for showing and hiding password when clicking on eye icon
  function show2() {
    setHidden2(!hidden2);
    var x = document.getElementById("confirmPassword");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  //for showing and hiding password when clicking on eye icon
  function show3() {
    setHidden3(!hidden3);
    var x = document.getElementById("pass3");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Schema,
    onSubmit,
  });

  const passRef = useRef();

  const passConfirmRef = useRef();

  //takes new and old password
  //edits user password
  //this component is then set to false (shows user profile instead)
  function onSubmit() {
    setLoading(true);

    const token = localStorage.getItem("token");

    const values = {
      password: passRef.current.value,
      password_confirmation: passConfirmRef.current.value
    };

    changePassword(values, token)
      .then((response) => {
        console.log(response);

        props.setChangePass(false);
      })
      .catch(function (err) {
        if (err.response.status === 401) {
          //used to change cart icon's current value to 0

          props.setCartItem(0);

          localStorage.removeItem("token");
          localStorage.removeItem("userPhone");
          localStorage.removeItem("userName");
          localStorage.removeItem("basket");
          //was used to store user's current step in cart process
          localStorage.removeItem("process");

          history("/login");
        }
        setMsgAr("برجاء التأكد من كلمة السر القديمة");
        setMsgEn("Please recheck your old password");
        setErrorMsgModel(true);
        setLoading(false);
      });
  }

  return (
    <div className="container-profile-header aboutusBody profileBody container-fluid fluid">
      <p className="upTable-title"> {t("profile.personal")} </p>

      <div className="userData">
        {loading ? (
          <div
            style={{
              height: "60vh",
            }}
            className="loader profileLoader"
          >
            <BeatLoader color={"#ffea13"} loading={loading} size={30} />
          </div>
        ) : null}

        <div
          style={
            loading
              ? {
                  display: "none",
                }
              : null
          }
        >
          <div className="upTop">
            <div
              style={{
                textAlign: "center",
              }}
            >
              <p className="addressTitle"> {t("profile.change_pass")} </p>
            </div>
          </div>{" "}
          <form onSubmit={formik.handleSubmit}>
            <div>


              <div className="inputDiv">
                <label> {t("Login.pass")} </label>{" "}
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <input
                    id="pass3"
                    type="password"
                    value={formik.values.password}
                    name="password"
                    ref={passRef}
                    required={true}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={
                      formik.errors.password && formik.touched.password
                        ? "invalid"
                        : null
                    }
                  />{" "}
                  {hidden3 ? (
                    <AiOutlineEyeInvisible
                      style={{
                        color: "#ACACAC",
                      }}
                      onClick={show3}
                      size={30}
                      className={i18n.language == "ar" ? "eye" : "eyeEn"}
                    />
                  ) : (
                    <AiOutlineEye
                      style={{
                        color: "#ACACAC",
                      }}
                      onClick={show3}
                      size={30}
                      className={i18n.language == "ar" ? "eye" : "eyeEn"}
                    />
                  )}{" "}
                  <span>
                    {" "}
                    {formik.errors.password && formik.touched.password
                      ? t(`${formik.errors.password}`)
                      : null}{" "}
                  </span>
                </div>
              </div>
              <div className="inputDiv">
                <label> {t("Login.confirmPass")} </label>{" "}
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <input
                    id="confirmPassword"
                    type="password"
                    name="confirmPassword"
                    value={formik.values.confirmPassword}
                    ref={passConfirmRef}
                    required={true}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={
                      formik.errors.confirmPassword &&
                      formik.touched.confirmPassword
                        ? "invalid"
                        : null
                    }
                  />{" "}
                  {hidden2 ? (
                    <AiOutlineEyeInvisible
                      style={{
                        color: "#ACACAC",
                      }}
                      onClick={show2}
                      size={30}
                      className={i18n.language == "ar" ? "eye" : "eyeEn"}
                    />
                  ) : (
                    <AiOutlineEye
                      style={{
                        color: "#ACACAC",
                      }}
                      onClick={show2}
                      size={30}
                      className={i18n.language == "ar" ? "eye" : "eyeEn"}
                    />
                  )}{" "}
                  <span>
                    {" "}
                    {formik.errors.confirmPassword &&
                    formik.touched.confirmPassword
                      ? t(`${formik.errors.confirmPassword}`)
                      : null}{" "}
                  </span>
                </div>{" "}
              </div>{" "}
            </div>

            <div className="formBtns">
              <button type="submit" className="dataEdit">
                {" "}
                {t("profile.save")}{" "}
              </button>{" "}
              <span> </span>{" "}
              <button
                type="button"
                onClick={() => props.setChangePass(false)}
                className="cancelEdit"
              >
                {" "}
                {t("profile.cancel")}{" "}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ProfileChangePass;
