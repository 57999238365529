import "../Styles/Medyaf.css";
import React, { useEffect, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";

import { useTranslation } from "react-i18next";

function AddressElgible(props) {
  const { t, i18n } = useTranslation();

  return (
    <div className="foodModal">
      <div onClick={() => props.setModal(false)} className="modalOverlay">
        {" "}
      </div>{" "}
      <div
        className="modal-content"
        style={{
          width: "90% ",
          height: "164px",
          maxWidth: "400px",
          minWidth: "337px",
          position: "fixed",
        }}
      >
        {/* <div style={{height:"85vh",overflow:"auto"}} className='modalBody'>*/}{" "}
        <div
          style={{
            textAlign: "center",
            height: "100%",
          }}
        >
          <p className="modal-p"> {t("address.no_near")} </p>{" "}
        </div>
      </div>{" "}
    </div>
  );
}

export default AddressElgible;
