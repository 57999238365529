import "../Styles/AboutUs.css";
import Navbar from "../Components/Navbar";
import { useRef, useState, useEffect } from "react";
import Footer from "../Components/Footer";
import { useTranslation } from "react-i18next";
import Slider from "../Components/Slider";
import { publications, medias } from "../api-nano";
import { Link } from "react-router-dom";
import { useParams, generatePath } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { BackgroundImage } from "react-image-and-background-image-fade";
import { getBanners } from "../api-nano";

function AboutUs(props) {
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(0);
  const [publication, setPublication] = useState([]);
  const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(true);
  const [banners, setBanners] = useState([]);
  let history = useNavigate();

  useEffect(() => {
    setLoading(true);
    let language = i18n.language == "ar" ? "ar" : "en";
    let countryId = 1;

    getBanners().then((response) => {
      const data = response.data.data;
      setBanners(data);
    });

    // publications().then((response) => {
    //   setPublication(response.data.data);
    // });

    // medias().then((response) => {
    //   setMedia(response.data.data);

    //   console.log(response.data.data);

    setLoading(false);
    // });
  }, [i18n.language, props.country]);

  //on clicking on a media div in media center user is redirected to /news (MediaCenter component found in /Pages) screen with chosen media div id as params
  const handleProceed = (postId) => {
    history(
      generatePath("/news/:postId", {
        postId,
      })
    );
  };

  return (
    <div>
      {" "}
      {loading ? (
        <div className="loader">
          <BeatLoader color={"#ffea13"} loading={loading} size={30} />
        </div>
      ) : (
        <>
          <Slider banners={banners} />{" "}
          <div className="aboutusBody container-fluid fluid">
            <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            ></div>

            {/* <div className={"bodyTitle"}>
              <p> {t("aboutUS.ourStory")} </p>{" "}
              <div>
                {" "}
                <p> {t("aboutUS.story")} </p>
              </div>
            </div> */}

            <div className="aboutus">
              <div
                className={
                  i18n.language == "en"
                    ? "aboutus-nav aboutus-navEn"
                    : "aboutus-nav"
                }
              >
                <p
                  onClick={() => setPage(0)}
                  className={page == 0 ? "aboutus-active" : null}
                >
                  {" "}
                  {t("aboutUS.ourStory")}{" "}
                </p>{" "}
                <p
                  onClick={() => setPage(4)}
                  className={page == 4 ? "aboutus-active" : null}
                >
                  {" "}
                  {t("aboutUS.Identity")}{" "}
                </p>{" "}
                <p
                  onClick={() => setPage(5)}
                  className={page == 5 ? "aboutus-active" : null}
                >
                  {" "}
                  {t("aboutUS.mission")}{" "}
                </p>
                {/*
                     <p onClick={()=>setPage(1)} className={page==1?"aboutus-active":null}>{t("aboutUS.history")} </p>*/}{" "}
                {/*
                     <p onClick={()=>setPage(2)} className={page==2?"aboutus-active":null}>{t("aboutUS.media")} </p>*/}{" "}
                {/*
                     <p onClick={()=>setPage(3)} className={page==3?"aboutus-active":null}>{t("aboutUS.magazine")} </p>*/}
              </div>
              {page == 0 ? (
                <div
                  className={
                    i18n.language == "ar"
                      ? "aboutus-body "
                      : "aboutus-body aboutus-bodyEn"
                  }
                >
                  <p className="aboutUs-title"> {t("aboutUS.ourStory")} </p>{" "}
                  <div className="abstrac">
                    {" "}
                    <p
                      className="abstrac-p1"
                      style={{
                        marginBottom: "0",
                      }}
                    >
                      {" "}
                      {t("aboutUS.story")}{" "}
                    </p>
                  </div>{" "}
                </div>
              ) : null}{" "}
              {page == 1 ? (
                <div
                  className={
                    i18n.language == "ar"
                      ? "aboutus-body "
                      : "aboutus-body aboutus-bodyEn"
                  }
                >
                  <p className="aboutUs-title"> {t("aboutUS.history")} </p>{" "}
                  <p className="history-details">
                    {" "}
                    {t("aboutUS.history-body")}{" "}
                  </p>{" "}
                </div>
              ) : null}
              {page == 2 ? (
                <div
                  className={
                    i18n.language == "ar"
                      ? "aboutus-body "
                      : "aboutus-body aboutus-bodyEn"
                  }
                >
                  <p className="aboutUs-title"> {t("aboutUS.media")} </p>{" "}
                  <div className="row gy-4">
                    {media &&
                      media.map((data, index) => (
                        <div className="col-lg-4 col-sm-6">
                          <BackgroundImage
                            onClick={() => handleProceed(data.id)}
                            src={data.image}
                            width="100%"
                            className="aboutusImg"
                          >
                            <div className="imgCaption">
                              <p>
                                {" "}
                                {i18n.language == "ar"
                                  ? data.titleAr
                                  : data.titleEn}{" "}
                              </p>
                            </div>{" "}
                          </BackgroundImage>
                        </div>
                      ))}

                    {media.length == 0 ? (
                      <div className="noData">
                        <p> {t("companySales.no_result")} </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}{" "}
              {page == 3 ? (
                <div
                  className={
                    i18n.language == "ar"
                      ? "aboutus-body "
                      : "aboutus-body aboutus-bodyEn"
                  }
                >
                  <p className="aboutUs-title"> {t("aboutUS.magazine")} </p>{" "}
                  <div className="row gy-4">
                    {" "}
                    {publication &&
                      publication.map((data, index) => (
                        <div className="col-12">
                          <div className="row ">
                            <div className="col-2 ">
                              <div
                                className="magazineImg"
                                style={{
                                  backgroundImage: `url(${data.image})`,
                                }}
                              ></div>
                            </div>{" "}
                            <div className="col-10 mag-Body">
                              <p className="mag-title">
                                {" "}
                                {i18n.language == "ar"
                                  ? data.titleAr
                                  : data.titleEn}{" "}
                              </p>{" "}
                              <div
                                className="mag-details"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    i18n.language == "ar"
                                      ? data.descriptionAr
                                      : data.descriptionEn,
                                }}
                              />
                              <p className="mag-details"> {data.createdAt} </p>{" "}
                            </div>{" "}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ) : null}
              {page === 4 ? (
                <div
                  className={
                    i18n.language == "ar"
                      ? "aboutus-body "
                      : "aboutus-body aboutus-bodyEn"
                  }
                >
                  <p className="aboutUs-title"> {t("aboutUS.Identity")} </p>{" "}
                  <p className="history-details">
                    {" "}
                    {t("aboutUS.Identity-detail")} <br />{" "}
                    {t("aboutUS.Identity-detail2")}{" "}
                  </p>{" "}
                </div>
              ) : null}{" "}
              {page === 5 ? (
                <div
                  className={
                    i18n.language == "ar"
                      ? "aboutus-body "
                      : "aboutus-body aboutus-bodyEn"
                  }
                >
                  <p className="aboutUs-title"> {t("aboutUS.mission")} </p>
                  <p className="history-details">
                    {" "}
                    {t("aboutUS.mission-detail")}{" "}
                  </p>
                </div>
              ) : null}{" "}
            </div>
          </div>
          {/* <Footer />{" "} */}
        </>
      )}{" "}
    </div>
  );
}

export default AboutUs;
