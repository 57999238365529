import React, { useState, useEffect } from 'react';
import { FaTimes, FaPercent, FaTruck } from 'react-icons/fa';
import { getPopupAdvert } from '../api-nano';
import '../Styles/PopupAdvert.css';

const PopupAdvert = () => {
    const [advert, setAdvert] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        fetchAdvert();
    }, []);

    const fetchAdvert = async () => {
        try {
            const response = await getPopupAdvert();
            if (response.data.data && response.data.data.length > 0) {
                setAdvert(response.data.data[0]);
                setTimeout(() => setIsOpen(true), 200);
            }
            console.log(response.data);
        } catch (error) {
            console.error('Error fetching advert:', error);
        }
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    if (!advert) return null;

    return (
        <div className={`popup-advert-container ${isOpen ? 'open' : 'closed'}`}>
            <div className={`popup-advert ${isOpen ? 'open' : 'closed'}`}>
                <button onClick={handleClose} className="close-button">
                    <FaTimes className="close-icon" />
                </button>

                <div className="advert-image-container">
                    <img
                        src={advert.image.original}
                        alt={advert.name}
                        className="advert-image"
                    />
                </div>

                <div className="advert-details">
                    {/* <h2 className="advert-title">{advert.name}</h2> */}
                    <p className="advert-subtitle">{advert.title}</p>
                    <p className="advert-subtitle">{advert.sub_title}</p>

                    {/* <div className="icons-container">
                        <div className="icon-wrapper">
                            <div className="icon-background">
                                <FaPercent className="icon" />
                            </div>
                            <span className="icon-text">25% Off</span>
                        </div>
                        <div className="icon-wrapper">
                            <div className="icon-background">
                                <FaTruck className="icon" />
                            </div>
                            <span className="icon-text">Free Delivery</span>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default PopupAdvert;